<template>
  <div>
    <div class="d-none d-lg-block">
      <div v-if="data && data.length > 0" class="row">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg mb-4"
          style="flex: 0 0 calc(100% / 5); max-width: calc(100% / 5)"
          v-for="(item, index) in data"
          :key="index"
        >
          <router-link :to="'/home/DetailProduk/' + item.slug">
            <div
              class="shadow-custom"
              style="
                display: flex;
                flex-direction: column;
                border-radius: 12px;
                overflow: hidden;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              "
            >
              <img
                :src="
                  String(item.image).includes('/https:')
                    ? item.image.slice(45, item.image.length)
                    : item.image
                "
                style="
                  width: 100%;
                  height: 232px;
                  object-fit: cover;
                  border-radius: 12px 12px 0 0;
                "
              />
              <div :class="item.isDiscount ? `btn-preordor` : `d-none`">
                <span
                  class="bgdiskons font-weight-bold"
                  style="
                    position: absolute;
                    right: 0;
                    top:  -230px;
                    /* margin-top: -232px;
                    margin-left: 99px; */
                  "
                >
                  {{ item.discount }} %
                </span>
              </div>

              <div :class="item.isPreorder == 'Y' ? `btn-preordor` : `d-none`">
                <span
                  style="
                     position: absolute;
                     right: 0;
                  "
                  class="bgpreorder font-weight-bold"
                >
                  Pre Order
                </span>
              </div>

              <div :class="item.freeOngkir == 'Y' ? `btn-preordor` : `d-none`">
                <span
                  style="
                    position: absolute;
                    left: 0;
    top: -232px;

                  "
                  class="bgfreeongkir font-weight-bold"
                >
                  Free Ongkir
                </span>
              </div>
              <div
                style="
                  padding: 10px;
                  background-color: #fff;
                  border-radius: 0 0 12px 12px;
                "
              >
                <p
                  class="mt-4 text-left"
                  style="
                    color: #1a1a1a;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.14px;
                    margin-bottom: 10px;
                    height: 40px;
                  "
                >
                  {{
                    item.name.length > 40
                      ? item.name.slice(0, 40).concat("...")
                      : item.name
                  }}
                </p>

                <p
                  class="text-left"
                  style="
                    color: #000;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: -0.16px;
                    margin-bottom: 10px;
                  "
                >
                  {{ item.isDiscount ? item.priceDiscount : item.price }}
                  <strike :class="item.isDiscount ? `` : `d-none`">
                    <small>{{ item.price }}</small>
                  </strike>
                </p>
                <p
                  class="text-left"
                  style="
                    color: #ca8c1e;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: -0.1px;
                  "
                >
                  <i
                    class="fas fa-map-marker-alt"
                    style="margin-right: 4px; color: red"
                  ></i>
                  {{ item.location }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <cardPlaceholder v-else />
    </div>
    <div class="d-lg-none">
      <div v-if="data && data.length > 0" class="row">
        <div
          class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
          v-for="(item, index) in data"
          :key="index"
        >
          <router-link :to="'/home/DetailProduk/' + item.slug">
            <div
              class="shadow-custom"
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                border-radius: 12px;
                overflow: hidden;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              "
            >
              <img
                :src="
                  String(item.image).includes('/https:')
                    ? item.image.slice(45, item.image.length)
                    : item.image
                "
                style="
                  width: 100%;
                  height: 128px;
                  object-fit: cover;
                  border-radius: 12px 12px 0 0;
                "
              />
              <div :class="item.isDiscount ? `btn-preordor` : `d-none`">
                <span
                  class="bgdiskons font-weight-bold"
                  style="
               position: absolute;
                    right: 0;
                    top: -232px;
                  "
                >
                  {{ item.discount }} %
                </span>
              </div>

              <div :class="item.isPreorder == 'Y' ? `btn-preordor` : `d-none`">
                <span
                  style="
                    position: absolute;
                    right: 0;
                  "
                  class="bgpreorder font-weight-bold"
                >
                  Pre Order
                </span>
              </div>

              <div :class="item.freeOngkir == 'Y' ? `btn-preordor` : `d-none`">
                <span
                  style="
                    position: absolute;
                    left: 0;
    top: -232px;
                  "
                  class="bgfreeongkir font-weight-bold"
                >
                  Free Ongkir
                </span>
              </div>
              <div
                style="
                  padding: 10px;
                  background-color: #fff;
                  border-radius: 0 0 12px 12px;
                "
              >
                <p
                  class="text-left mt-4 "
                  style="
                    color: var(--Neutral-60, #1a1a1a);
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 160% */
                    letter-spacing: -0.1px;
                    white-space: nowrap; /* Menambahkan ini untuk memastikan satu baris */
                  "
                >
                  {{
                    item.name.length > 25
                      ? item.name.slice(0, 25).concat("...")
                      : item.name
                  }}
                </p>

                <p
                  class="text-left"
                  style="
                    color: var(--Neutral-60, #1a1a1a);
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19px; /* 135.714% */
                    letter-spacing: 0.28px;
                  "
                >
                  {{ item.isDiscount ? item.priceDiscount : item.price }}
                  <strike :class="item.isDiscount ? `` : `d-none`">
                    <small>{{ item.price }}</small>
                  </strike>
                </p>
                <p
                  class="text-left"
                  style="
                    color: var(--Secondary-30, #fdb739);
                    font-size: 8px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px; /* 175% */
                    letter-spacing: 0.08px;
                  "
                >
                  <i
                    class="fas fa-map-marker-alt"
                    style="margin-right: 4px; color: red"
                  ></i>
                  {{ item.location }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <cardPlaceholder v-else />
    </div>
  </div>
</template>
  
  <script>
import cardPlaceholder from "../../components/card/cardplaceholder";
export default {
  data() {
    return {};
  },
  props: {
    data: {
      // type: Array,
      required: true,
    },
  },
  components: {
    cardPlaceholder,
  },
};
</script>
  <style lang="scss" scoped>
.body-home {
  // jaja
  //   background: #eff6f8
  /* ebh */
  background: #f2f2f2;
}

.header-filter {
  border-radius: 15px 15px 0 0;
}

.kotak-filter {
  color: #65b0c9 !important;
  font-size: 25px;
}

.kotak-tombol-reset a {
  background: rgb(224, 116, 116);
  color: white;
}

.ul-list-filter {
  list-style-type: none;
}

.kotak-kondisi {
  position: relative;
  top: -12px;
}

.kotak-stock {
  position: relative;
  top: -8.5px;
}

.short-product {
  padding: 15px 0 15px 0;
  position: relative;
  top: -28px;
  border-radius: 0 0 15px 15px;
}
.short-product div {
  margin-left: 17.5%;
}

.text-label {
  color: rgb(117, 116, 116);
}

.label-order {
  font-size: 16px;
}

.label-order-ready {
  font-size: 14px;
}

.bg-filter {
  background: #ffd68d;
}
.card-product {
  border-radius: 10px;
  width: 200px;
  height: 300px;
}

.ImgSizeProduct {
  // height:150px;
  // width:150px;
}
.ImgSizeProduct img {
  border-radius: 20px 20px 0 0;
  max-width: 165px;
  width: 100%;
  max-height: 150px;
  height: 100%;
  object-fit: cover;
  /* border:1px solid rgb(224, 224, 224); */
}
.text-product {
  color: #65b0c9 !important;
  font-weight: bold;
  text-align: left;
}
.text-price {
  color: #fdb739;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
}
.kotak-location {
  position: relative;
  display: flex;
  top: -10px;
}
.kotak-location small {
  position: absolute;
  left: 15px;
}
.kotak-location img {
  width: 14px;
  height: 14px;
  position: absolute;
}
a:hover {
  text-decoration: none;
}

.card-product .btn-wishlist {
  position: absolute;
  right: 15px;
  top: 0px;
}
.bgdiskons {
  color: #fff;
  background-color: #65b0c9;
  padding: 3px 3px 8px 3px;
  font-size: 15px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  /* font-weight: bold; */
}

.card-product .btn-wishlist2 {
  position: absolute;
  right: 4px;
  top: 130px;
}
.bgpreorder {
  color: #fff;
  background-color: #da8922bd;
  padding: 3px 3px 8px 3px;
  font-size: 12px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* font-weight: bold; */
}

.card-product .btn-wishlist3 {
  position: absolute;
  left: 10px;
  top: 130px;
}
.bgfreeongkir {
  color: #fff;
  background-color: #fb7181cc;
  padding: 3px 3px 8px 3px;
  font-size: 12px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* font-weight: bold; */
}
.btn-preordor{
  position: relative;
}
</style>