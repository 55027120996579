<template>
  <div>
    <div class="d-none d-lg-block">
      <div style="margin-top: -2%" class="container p-3">
        <div class="row">
          <div class="col-md-3">
            <sideBar />
          </div>
          <div class="col-md-9">
            <div
              class="d-flex flex-column text-left bd-highlight mb-3 shadow-custom card-product"
            >
              <div class="py-3 px-2 bd-highlight card-header-danakembali">
                <div class="d-flex justify-content-start bd-highlight">
                  <div class="ml-3 bd-highlight mr-auto">
                    <h5 class="text-white font-weight-bold d-block">
                      Pemberitahuan Saya
                    </h5>
                  </div>
                </div>
              </div>
              <div
                class="bd-highlight bg-white p-4 parent-card-body-danakembali shadow-custom"
              >
                <div v-if="paginatedData.length > 0">
                  <div
                    v-for="(item, index) in paginatedData"
                    :key="index"
                    style="width: 100%"
                    class="mt-3 d-flex align-items-start flex-column card-body-danakembali"
                  >
                    <li
                      class="list-group-item hover-primary"
                      :class="item.read == 'T' ? ` bg-light-blue ` : ` `"
                      style="width: 100%"
                    >
                      <a
                        type="button"
                        @click="
                          goToDetailPemberitahuan(item.id_notif, item.invoice)
                        "
                        class="text-dark"
                      >
                        <div class="row">
                          <div class="col-md-0">
                            <span class="ml-2 mt-2 center-middle-custom">
                              <img
                                src="https://nimda.jaja.id/asset/images/icon-jaja/Icon%20caution-10.png"
                                width="40"
                                v-if="item.title == 'Komplain Pesanan'"
                                alt=""
                              />
                              <img
                                src="https://nimda.jaja.id/asset/images/icon-jaja/Icon Cart-57.png"
                                width="40"
                                alt=""
                                v-else
                              />
                            </span>
                          </div>
                          <div class="col">
                            <p class="mb-0">
                              <small
                                ><b>{{ item.title }}</b></small
                              >
                            </p>
                            <p class="mb-0">
                              <small>{{ item.text }}</small>
                            </p>
                            <small class="mb-0 clsa">{{
                              item.created_date
                            }}</small>
                          </div>
                        </div>
                      </a>
                    </li>
                  </div>
                </div>
                <div class="row" v-else-if="isFinish">
                  <div class="col-md-12 col-12 carousel-cell is-selected">
                    <div
                      class="CheckShowCheck card-product mb-3"
                      style="background: white !important"
                    >
                      <div class="text-center">
                        <div></div>
                        <img
                          slot="image"
                          src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                          class="img-fluid"
                          style="
                            max-height: 400px;
                            max-width: 100%;
                            width: auto;
                            border-radius: 10px;
                          "
                          alt=""
                        />
                        <div class="text-dark">
                          <div class="keterangan ResetKet_JajaID">
                            <h4
                              class="text-product one-line fsxx text-center"
                              style="margin-top: -20px"
                            >
                              Looks like the data you are looking for is empty.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container mt-4" v-else>
                  <div class="row" v-for="index in 4" :key="index">
                    <div class="col-2">
                      <content-placeholders :rounded="true">
                        <content-placeholders-img
                          style="
                            width: 100px;
                            height: 50px;
                            margin-bottom: 20px;
                          "
                        />
                      </content-placeholders>
                    </div>
                    <div class="col-10">
                      <content-placeholders :rounded="true">
                        <content-placeholders-heading
                          style="margin-bottom: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
                <div v-if="totalPages > 1" class="pagination-controls">
                  <ul class="pagination">
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === 1 }"
                    >
                      <a
                        class="page-link"
                        href="#"
                        @click.prevent="previousPage"
                        >  <i class="fas fa-arrow-left"></i></a
                      >
                    </li>
                    <li
                      v-for="page in paginationPages"
                      :key="page"
                      class="page-item"
                      :class="{
                        active: currentPage === page,
                        disabled: page === '...',
                      }"
                    >
                      <a
                        v-if="page !== '...'"
                        class="page-link"
                        href="#"
                        @click.prevent="goToPage(page)"
                        >{{ page }}</a
                      >
                      <span v-else class="page-link">...</span>
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === totalPages }"
                    >
                      <a class="page-link" href="#" @click.prevent="nextPage"
                        >  <i class="fas fa-arrow-right"></i></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div style="margin-top: -42%" class="container-m p-3">
        <div class="row">
          <div class="col-md-12">
            <div
              class="d-flex flex-column text-left bd-highlight mb-3 shadow-custom card-product"
            >
              <div class="py-3 px-2 bd-highlight card-header-danakembali">
                <div class="d-flex justify-content-start bd-highlight">
                  <div class="ml-3 bd-highlight mr-auto">
                    <h5 class="text-white font-weight-bold d-block">
                      Pemberitahuan Saya
                    </h5>
                  </div>
                </div>
              </div>
              <div
                class="bd-highlight bg-white p-4 parent-card-body-danakembali shadow-custom"
              >
                <div v-if="paginatedData.length > 0">
                  <div
                    v-for="(item, index) in paginatedData"
                    :key="index"
                    style="width: 100%"
                    class="d-flex align-items-start flex-column card-body-danakembali mt-3"
                  >
                    <li
                      class="list-group-item hover-primary"
                      :class="item.read == 'T' ? ` bg-light-blue ` : ` `"
                      style="width: 100%"
                    >
                      <a
                        type="button"
                        @click="
                          goToDetailPemberitahuan(item.id_notif, item.invoice)
                        "
                        class="text-dark"
                      >
                        <div class="row">
                          <div class="col-md-0">
                            <span class="ml-2 mt-2 center-middle-custom">
                              <img
                                src="https://nimda.jaja.id/asset/images/icon-jaja/Icon%20caution-10.png"
                                width="40"
                                v-if="item.title == 'Komplain Pesanan'"
                                alt=""
                              />
                              <img
                                src="https://nimda.jaja.id/asset/images/icon-jaja/Icon Cart-57.png"
                                width="40"
                                alt=""
                                v-else
                              />
                            </span>
                          </div>
                          <div class="col">
                            <p class="mb-0">
                              <small
                                ><b>{{ item.title }}</b></small
                              >
                            </p>
                            <p class="mb-0">
                              <small>{{ item.text }}</small>
                            </p>
                            <small class="mb-0 clsa">{{
                              item.created_date
                            }}</small>
                          </div>
                        </div>
                      </a>
                    </li>
                  </div>
                </div>
                <div class="row" v-else-if="isFinish">
                  <div class="col-md-12 col-12 carousel-cell is-selected">
                    <div
                      class="CheckShowCheck card-product mb-3"
                      style="background: white !important"
                    >
                      <div class="text-center">
                        <div></div>
                        <img
                          slot="image"
                          src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                          class="img-fluid"
                          style="
                            max-height: 400px;
                            max-width: 100%;
                            width: auto;
                            border-radius: 10px;
                          "
                          alt=""
                        />
                        <div class="text-dark">
                          <div class="keterangan ResetKet_JajaID">
                            <h4
                              class="text-product one-line fsxx text-center"
                              style="margin-top: -20px"
                            >
                              Looks like the data you are looking for is empty.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container mt-4" v-else>
                  <div class="row" v-for="index in 4" :key="index">
                    <div class="col-2">
                      <content-placeholders :rounded="true">
                        <content-placeholders-img
                          style="
                            width: 100px;
                            height: 50px;
                            margin-bottom: 20px;
                          "
                        />
                      </content-placeholders>
                    </div>
                    <div class="col-10">
                      <content-placeholders :rounded="true">
                        <content-placeholders-heading
                          style="margin-bottom: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
                <div v-if="totalPages > 1" class="pagination-controls">
                  <ul class="pagination">
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === 1 }"
                    >
                      <a
                        class="page-link"
                        href="#"
                        @click.prevent="previousPage"
                        ><i class="mt-2 fas fa-chevron-left"></i
                          ></a
                      >
                    </li>
                    <li
                      v-for="page in paginationPages"
                      :key="page"
                      class="page-item"
                      :class="{
                        active: currentPage === page,
                        disabled: page === '...',
                      }"
                    >
                      <a
                        class="page-link"
                        href="#"
                        @click.prevent="goToPage(page)"
                        >  <p class="mt-2">{{ page }}</p></a
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === totalPages }"
                    >
                      <a class="page-link" href="#" @click.prevent="nextPage"
                        ><i class="mt-2 fas fa-chevron-right"></i
                          ></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <br />
        <NavBar />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import VueBase64FileUpload from "vue-base64-file-upload";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import sideBar from "../../components/sidebar/sideBar";
import NavBar from "../../components/master/navbarbuttom.vue";

export default {
  data() {
    return {
      dataNotif: [],
      isFinish: false,
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  components: {
    sideBar,
    // DatePicker,
    // VueBase64FileUpload,
    NavBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
    totalPages() {
      return Math.ceil(this.dataNotif.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.dataNotif.slice(start, end);
    },
    paginationPages() {
      const total = this.totalPages;
      const current = this.currentPage;
      const delta = 2;
      const range = [];

      if (total <= 5) {
        for (let i = 1; i <= total; i++) {
          range.push(i);
        }
      } else {
        if (current <= delta + 1) {
          for (let i = 1; i <= Math.min(total, delta + 1); i++) {
            range.push(i);
          }
          range.push("...");
          range.push(total);
        } else if (current >= total - delta) {
          range.push(1);
          range.push("...");
          for (let i = total - delta; i <= total; i++) {
            range.push(i);
          }
        } else {
          range.push(1);
          range.push("...");
          for (let i = current - 1; i <= current + 1; i++) {
            range.push(i);
          }
          range.push("...");
          range.push(total);
        }
      }

      return range;
    },
  },
  mounted() {
    this.getPemberitahuan();
    window.scrollTo(0, 0);
  },
  methods: {
    // Metode untuk mendapatkan data pemberitahuan dari server
    getPemberitahuan() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}notifikasi/${this.user.id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.isFinish = true;
            this.dataNotif = res.data.notifikasi;
          }
        })
        .catch((error) => console.log("error", error));
    },
    // Metode untuk navigasi ke halaman detail pemberitahuan
    goToDetailPemberitahuan(id, invoice) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}notifikasi/updateNotif?id_notif=${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            window.location.href = `https://jaja.id/order/${invoice}`;
          }
        })
        .catch((error) => console.log("error", error));
    },
    // Metode untuk navigasi ke halaman sebelumnya
    previousPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    // Metode untuk navigasi ke halaman berikutnya
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    // Metode untuk navigasi ke halaman tertentu
    goToPage(page) {
      if (page !== "..." && page !== this.currentPage) {
        if (page === 1) {
          this.currentPage = 1;
        } else if (page === this.totalPages) {
          this.currentPage = this.totalPages;
        } else {
          this.currentPage = page;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.iconPayment {
  width: 50%;
  margin-right: 5%;
}
.mx-datepicker {
  width: 100%;
}
.parent-card-body-danakembali {
  border-radius: 0 0 10px 10px;
}
.card-header-danakembali {
  background: rgb(100, 175, 200);
  border-radius: 10px 10px 0 0;
}
.card-body-danakembali {
  width: 100%;
  height: auto;
  border: 1px solid rgb(100, 175, 200);
  border-radius: 10px;
}
.kotak-profil-1 {
  margin-right: 15%;
}
.bg-jaja {
  background: rgb(100, 175, 200);
}
.container-m {
  padding: 10px;
}
.page-link {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-right: 5px;
  margin-left: 5px;
  color: #65b0c9;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  font-weight: bold;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
  border-radius: 50%;
  font-weight: bold;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #65b0c9;
  border-color: #65b0c9;
  font-weight: bold;
}
.pagination {
  justify-content: center;
  margin-top: 30px;
}
</style>
