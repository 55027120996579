<template>
  <div>
    <div class="d-none d-lg-block">
      <div style="margin-top: -2%" class="container p-3">
        <div class="row" v-for="(item, index) in data" :key="index">
          <div class="col-md-3">
            <sideBar />
          </div>
          <div class="col-md-9">
            <div
              class="d-flex flex-column text-left bd-highlight mb-3 shadow-custom card-product"
            >
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-white">
                      Detail Komplain Pesanan
                    </h5>
                  </div>
                  <div class="pt-1 bd-highlight">
                    <h6 class="d-flex font-weight-bold">
                      <router-link :to="'/order/' + item.invoice">
                        <button
                          type="button"
                          class="btn btn-warning btn-sm text-white font-weight-bold"
                        >
                          <i class="fas fa-angle-left ver-middle"></i
                          >&nbsp;<span class="ver-middle">Kembali</span>
                        </button>
                      </router-link>
                    </h6>
                  </div>
                </div>
              </div>
              <div
                class="bd-highlight bg-white p-4 parent-card-body-danakembali shadow-custom"
              >
                <div>
                  <div
                    class="d-flex align-items-start flex-column card-body-danakembali"
                  >
                    <div class="col-md-12">
                      <div class="containere mb-4 mt-4">
                        <section
                          class="step-indicator"
                          v-if="!item.solusi && item.status == 'request'"
                        >
                          <div class="step step1 active">
                            <div class="step-icon mb-2">
                              <i class="fas fa-file"></i>
                            </div>
                            <p>Permintaan Komplain</p>
                          </div>
                          <div class="indicator-line"></div>
                          <div class="step step3">
                            <div class="step-icon mb-2">
                              <i class="fas fa-clipboard-check"></i>
                            </div>
                            <p>Dalam Proses</p>
                          </div>
                        </section>
                        <section v-else-if="item.solusi == 'refund'">
                          <div
                            class="step-indicator"
                            v-if="item.status === 'completed'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step4 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="
                              item.alasan_tolak_by_seller && item.resi_seller
                            "
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="
                              item.resi_customer != null &&
                              item.alasan_tolak_by_seller == null &&
                              item.status != 'complated'
                            "
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.resi_customer == null"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                        </section>
                        <section v-else-if="item.solusi == 'change'">
                          <div
                            class="step-indicator"
                            v-if="item.status === 'completed'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step4 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.resi_seller"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="
                              item.resi_customer != null &&
                              item.alasan_tolak_by_seller == null &&
                              item.status != 'complated'
                            "
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.resi_customer == null"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                        </section>
                        <section v-else-if="item.solusi == 'lengkapi'">
                          <div
                            class="step-indicator"
                            v-if="item.status == 'completed'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step4 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.status == 'sendback'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                        </section>
                        <!-- <section class="step-indicator" v-else-if="item.solusi == 'tolak'">
                                            <div class="step step1 active">
                                                <div class="step-icon mb-2 ">
                                                    <i class="fas fa-file"></i>
                                                </div>
                                                <p>Permintaan Komplain</p>
                                            </div>
                                            <div class="indicator-line active"></div>
                                            <div class="step step4 active ">
                                                <div class="step-icon mb-2">
                                                    <i class="fas fa-download"></i>
                                                </div>
                                            <p>Komplain Selesai </p>
                                            </div>					
                                        </section> -->
                      </div>
                      <br />

                      <div class="row">
                        <div class="col mt-3">
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Status Komplain :</span
                            >
                            <br />
                            <small
                              class="fsxx clsa"
                              v-if="item.status == 'request'"
                              >Menunggu Konfirmasi<br
                            /></small>
                            <small
                              class="fsxx clsa"
                              v-if="
                                item.status == 'confirmed' &&
                                item.solusi == 'refund'
                              "
                            >
                              Komplain Telah Dikonfirmasi oleh Penjual<br />
                              Silahkan kirimkan barang yang akan
                              dikembalikan!<br
                            /></small>
                            <small
                              class="fsxx clsa"
                              v-if="item.status == 'sendback'"
                              >Kirim Kembali<br
                            /></small>
                            <small
                              class="fsxx clsa"
                              v-if="item.status == 'completed'"
                              >Selesai<br
                            /></small>
                            <small class="fsxx clsa"> </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Komplain Mengenai :</span
                            >
                            <br />
                            <small class="fsxx clsa">
                              <span v-if="item.jenis_komplain == 'barang'">
                                Barang Tidak Sesuai :
                              </span>
                              <span v-else> Lainnya : </span>
                              <br />&rsaquo; {{ item.judul_komplain }} <br />
                              Catatan : <br />&rsaquo;
                              {{ item.komplain }}
                            </small>
                          </div>
                          <div
                            class="mb-3"
                            v-if="
                              item.status == 'confirmed' &&
                              (item.solusi == 'refund' ||
                                item.solusi == 'change')
                            "
                          >
                            <span class="fsxx text-primary-jaja text-bold">
                              Kirimkan Barang :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              <button
                                type="button"
                                class="mt-1 btn btn-sm btn-primary bg-primary-jaja br-color-primary-jaja text-white font-weight-bold br-button-jaja"
                                @click="kirimkanBarangModal()"
                              >
                                <span class="ver-middle">Kirimkan Barang</span>
                              </button>
                            </small>
                          </div>

                          <div
                            class="mb-3"
                            v-if="item.resi_customer && item.date_resi_cust"
                          >
                            <span class="fsxx text-primary-jaja text-bold"
                              >Pengirim dari Pembeli :</span
                            >
                            <br />
                            <small class="fsxx clsa">
                              No. Resi <br />&rsaquo; {{ item.resi_customer }}
                              <br />
                              Tanggal Kirim : <br />&rsaquo;
                              {{ item.date_resi_cust }}
                            </small>
                          </div>
                          <div
                            class="mb-3"
                            v-if="item.resi_seller && item.date_resi_seller"
                          >
                            <span class="fsxx text-primary-jaja text-bold"
                              >Pengirim dari Penjual :</span
                            >
                            <br />
                            <small class="fsxx clsa">
                              No. Resi <br />&rsaquo; {{ item.resi_seller }}
                              <br />
                              Tanggal Kirim : <br />&rsaquo;
                              {{ item.date_resi_seller }}
                            </small>
                          </div>
                        </div>

                        <div class="col mt-3">
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >No. Invoice :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              <router-link
                                :to="'/order/' + item.invoice"
                                class="text-dark"
                              >
                                {{ item.invoice }}
                              </router-link>
                            </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Tanggal Pengajuan :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              {{ item.created_date }}
                            </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Batas Waktu :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              {{ item.complain_limit }}
                            </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Produk yang dikomplain :</span
                            ><br />
                            <div
                              class="media"
                              v-for="(a, index) in item.product"
                              :key="index"
                            >
                              <div class="mt-1">
                                <a href="#" style="color: black">
                                  <img
                                    :src="a.image"
                                    class="img-thumbnail img-responsive"
                                    width="40px"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div class="media-body">
                                <div class="ml-2">
                                  <a href="#" style="color: black">
                                    <small class="mb-0" title="Ggg">
                                      {{ a.name }}
                                    </small>
                                    <br />
                                  </a>
                                  <small class="clsa"
                                    >{{ a.totalPriceCurrencyFormat }} </small
                                  ><br />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Bukti Komplain:</span
                            ><br />

                            <a
                              :href="getFullUrl(item.gambar1)"
                              style="color: black"
                              v-if="item.gambar1 != null"
                              target="_blank"
                            >
                              <img
                                :src="
                                  String(item.gambar1).includes(
                                    'https://jaja.id/'
                                  )
                                    ? addStr(item.gambar1, 8, 'jsonx.')
                                    : item.gambar1
                                "
                                width="80"
                                class="img-thumbnail img-responsive mt-2 mb-2"
                                alt=""
                              />
                            </a>

                            <br />
                            <a
                              :href="item.video"
                              style="color: black"
                              v-if="item.video != null"
                            >
                              <video
                                :src="item.video"
                                width="80"
                                class="img-thumbnail img-responsive mt-2 mb-2"
                              ></video>
                            </a>
                          </div>

                          <div
                            class="mb-3 bg-light border"
                            v-if="item.solusi != null"
                          >
                            <div class="p-2">
                              <span class="fsxx text-primary-jaja text-bold"
                                >Solusi : <br
                              /></span>
                              <small
                                class="fsxx clsa text-bold text-primary-jaja ml-2"
                                v-if="item.solusi == 'refund'"
                                >Pengembalian Dana <br
                              /></small>
                              <small
                                class="fsxx clsa text-bold text-primary-jaja ml-2"
                                v-if="item.solusi == 'lengkapi'"
                                >Lengkapi Barang <br
                              /></small>
                              <small
                                class="fsxx clsa text-bold text-primary-jaja ml-2"
                                v-if="item.solusi == 'change'"
                                >Tukar Barang <br
                              /></small>
                              <small
                                class="fsxx clsa ml-2"
                                v-if="item.catatan_solusi"
                                >Catatan : {{ item.catatan_solusi }}</small
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col mt-3">
                          <div class="mb-3 mt-5">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Syarat Pengembalian Dana:</span
                            >
                            <br />
                            <ol
                              class="small"
                              style="padding-left: 15px !important"
                            >
                              <li>
                                Pembeli harus mengirim barang ke alamat penjual
                                menggunakan jasa kurir terdekat
                              </li>
                              <li>
                                Pembeli harus menginput nomor resi pengiriman,
                                sebagai bukti barang telah dikirim
                              </li>
                              <li>
                                Setalah penjual menerima produk uang akan
                                diproses untuk dikembalikan ke rekening pembeli.
                                Silahkan tambahkan nomor rekening jika belum ada
                              </li>
                            </ol>
                          </div>
                        </div>
                        <div class="col mt-3">
                          <div class="mb-3 mt-5">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Syarat Return Barang:</span
                            >
                            <br />
                            <ol
                              class="small"
                              style="padding-left: 15px !important"
                            >
                              <li>
                                Pembeli harus mengirim barang ke alamat penjual
                                menggunakan jasa kurir terdekat
                              </li>
                              <li>
                                Pembeli harus menginput nomor resi pengiriman,
                                sebagai bukti barang telah dikirim
                              </li>
                              <li>
                                Setalah penjual menerima produk, penjual akan
                                mengirim kembali barang yang sesuai
                              </li>
                              <li>
                                Setalah barang yg dikirim sampai, pembeli menkan
                                tombol terima pesanan
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      <section v-if="item.status === 'sendback'">
                        <button
                          class="btn-terima-barang"
                          @click="handleTerimaBarang"
                        >
                          Terima Barang
                        </button>
                      </section>
                      <div class="mt-3 row">
                        <div class="col mb-3">
                          <small class="fsxx text-danger"
                            >Note : biaya pengiriman ditanggung oleh pihak yang
                            mengirim</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="myModalKirimkanBarang">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title font-weight-bold">Kirimkan Barang</h5>
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <div v-if="isLoading" class="text-center">
                    <img
                      src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                      alt=""
                    />
                  </div>
                  <div v-else>
                    <form @submit.prevent="runKirimkanBarang">
                      <div
                        class="alert alert-dismissible fade show mt-2"
                        role="alert"
                        style="color: #6c757d; background-color: #d9f9ff"
                      >
                        <span class=""
                          >Kirimkan Barang Sesuai dengan Pesanan yang Dikomplain
                          !</span
                        ><br />
                      </div>
                      <div class="form-group">
                        <input
                          type="hidden"
                          class=""
                          id="id_data"
                          name="id_data"
                        />
                        <input
                          type="hidden"
                          class=""
                          :value="item.invoice"
                          id="invoice"
                          name="invoice"
                        />
                      </div>
                      <div class="form-group">
                        <p class="text-left">Masukkan Resi Pengiriman :</p>
                        <input
                          type="text"
                          class="form-control"
                          id="resi_customer"
                          v-model="resi_cust"
                          name="resi_customer"
                          placeholder="Masukkan Resi"
                          required
                        />
                      </div>
                      <div class="modal-footer">
                        <button
                          :disabled="isLoading"
                          type="button"
                          class="btn btn-secondary font-weight-bold"
                          data-dismiss="modal"
                          style="background: #fdb738; border-color: #fdb738"
                        >
                          Close
                        </button>
                        <button
                          :disabled="isLoading"
                          type="submit"
                          class="btn btn-primary font-weight-bold"
                          style="background: #64b0c9; border-color: #64b0c9"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div style="margin-top: -40%" class="container-fluid p-3">
        <div class="row" v-for="(item, index) in data" :key="index">
      
          <div class="col-md-9">
            <div
              class="d-flex flex-column text-left bd-highlight mb-3 shadow-custom card-product"
            >
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-black">
                      Detail Komplain Pesanan
                    </h5>
                  </div>
                  <div class="pt-1 bd-highlight">
                    <h6 class="d-flex font-weight-bold">
                      <router-link :to="'/order/' + item.invoice">
                        <button
                          type="button"
                          class="btn btn-warning btn-sm text-white font-weight-bold"
                        >
                          <i class="fas fa-angle-left ver-middle"></i
                          >&nbsp;<span class="ver-middle">Kembali</span>
                        </button>
                      </router-link>
                    </h6>
                  </div>
                </div>
              </div>
              <div
                class="bd-highlight bg-white p-4 parent-card-body-danakembali shadow-custom"
              >
                <div>
                  <div
                    class="d-flex align-items-start flex-column card-body-danakembali"
                  >
                    <div class="col-md-12">
                      <div class="containere mb-4 mt-4">
                        <section
                          class="step-indicator"
                          v-if="!item.solusi && item.status == 'request'"
                        >
                          <div class="step step1 active">
                            <div class="step-icon mb-2">
                              <i class="fas fa-file"></i>
                            </div>
                            <p>Permintaan Komplain</p>
                          </div>
                          <div class="indicator-line"></div>
                          <div class="step step3">
                            <div class="step-icon mb-2">
                              <i class="fas fa-clipboard-check"></i>
                            </div>
                            <p>Dalam Proses</p>
                          </div>
                        </section>
                        <section v-else-if="item.solusi == 'refund'">
                          <div
                            class="step-indicator"
                            v-if="item.status === 'completed'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step4 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="
                              item.alasan_tolak_by_seller && item.resi_seller
                            "
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="
                              item.resi_customer != null &&
                              item.alasan_tolak_by_seller == null &&
                              item.status != 'complated'
                            "
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.resi_customer == null"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                        </section>
                        <section v-else-if="item.solusi == 'change'">
                          <div
                            class="step-indicator"
                            v-if="item.status === 'completed'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step4 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.resi_seller"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="
                              item.resi_customer != null &&
                              item.alasan_tolak_by_seller == null &&
                              item.status != 'complated'
                            "
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.resi_customer == null"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                        </section>
                        <section v-else-if="item.solusi == 'lengkapi'">
                          <div
                            class="step-indicator"
                            v-if="item.status == 'completed'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step2 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-shipping-fast"></i>
                              </div>
                              <p>Menunggu Pengiriman</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step4 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                          <div
                            class="step-indicator"
                            v-else-if="item.status == 'sendback'"
                          >
                            <div class="step step1 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-file"></i>
                              </div>
                              <p>Permintaan Komplain</p>
                            </div>
                            <div class="indicator-line active"></div>
                            <div class="step step3 active">
                              <div class="step-icon mb-2">
                                <i class="fas fa-clipboard-check"></i>
                              </div>
                              <p>Dalam Proses</p>
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step4">
                              <div class="step-icon mb-2">
                                <i class="fas fa-download"></i>
                              </div>
                              <p>Komplain Selesai</p>
                            </div>
                          </div>
                        </section>
                        <!-- <section class="step-indicator" v-else-if="item.solusi == 'tolak'">
                                            <div class="step step1 active">
                                                <div class="step-icon mb-2 ">
                                                    <i class="fas fa-file"></i>
                                                </div>
                                                <p>Permintaan Komplain</p>
                                            </div>
                                            <div class="indicator-line active"></div>
                                            <div class="step step4 active ">
                                                <div class="step-icon mb-2">
                                                    <i class="fas fa-download"></i>
                                                </div>
                                            <p>Komplain Selesai </p>
                                            </div>					
                                        </section> -->
                      </div>
                      <br />

                      <div class="row">
                        <div class="col mt-3">
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Status Komplain :</span
                            >
                            <br />
                            <small
                              class="fsxx clsa"
                              v-if="item.status == 'request'"
                              >Menunggu Konfirmasi<br
                            /></small>
                            <small
                              class="fsxx clsa"
                              v-if="
                                item.status == 'confirmed' &&
                                item.solusi == 'refund'
                              "
                            >
                              Komplain Telah Dikonfirmasi oleh Penjual<br />
                              Silahkan kirimkan barang yang akan
                              dikembalikan!<br
                            /></small>
                            <small
                              class="fsxx clsa"
                              v-if="item.status == 'sendback'"
                              >Kirim Kembali<br
                            /></small>
                            <small
                              class="fsxx clsa"
                              v-if="item.status == 'completed'"
                              >Selesai<br
                            /></small>
                            <small class="fsxx clsa"> </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Komplain Mengenai :</span
                            >
                            <br />
                            <small class="fsxx clsa">
                              <span v-if="item.jenis_komplain == 'barang'">
                                Barang Tidak Sesuai :
                              </span>
                              <span v-else> Lainnya : </span>
                              <br />&rsaquo; {{ item.judul_komplain }} <br />
                              Catatan : <br />&rsaquo;
                              {{ item.komplain }}
                            </small>
                          </div>
                          <div
                            class="mb-3"
                            v-if="
                              item.status == 'confirmed' &&
                              (item.solusi == 'refund' ||
                                item.solusi == 'change')
                            "
                          >
                            <span class="fsxx text-primary-jaja text-bold">
                              Kirimkan Barang :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              <button
                                type="button"
                                class="mt-1 btn btn-sm btn-primary bg-primary-jaja br-color-primary-jaja text-white font-weight-bold br-button-jaja"
                                @click="kirimkanBarangModal()"
                              >
                                <span class="ver-middle">Kirimkan Barang</span>
                              </button>
                            </small>
                          </div>

                          <div
                            class="mb-3"
                            v-if="item.resi_customer && item.date_resi_cust"
                          >
                            <span class="fsxx text-primary-jaja text-bold"
                              >Pengirim dari Pembeli :</span
                            >
                            <br />
                            <small class="fsxx clsa">
                              No. Resi <br />&rsaquo; {{ item.resi_customer }}
                              <br />
                              Tanggal Kirim : <br />&rsaquo;
                              {{ item.date_resi_cust }}
                            </small>
                          </div>
                          <div
                            class="mb-3"
                            v-if="item.resi_seller && item.date_resi_seller"
                          >
                            <span class="fsxx text-primary-jaja text-bold"
                              >Pengirim dari Penjual :</span
                            >
                            <br />
                            <small class="fsxx clsa">
                              No. Resi <br />&rsaquo; {{ item.resi_seller }}
                              <br />
                              Tanggal Kirim : <br />&rsaquo;
                              {{ item.date_resi_seller }}
                            </small>
                          </div>
                        </div>

                        <div class="col mt-3">
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >No. Invoice :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              <router-link
                                :to="'/order/' + item.invoice"
                                class="text-dark"
                              >
                                {{ item.invoice }}
                              </router-link>
                            </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Tanggal Pengajuan :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              {{ item.created_date }}
                            </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Batas Waktu :
                            </span>
                            <br />
                            <small class="fsxx clsa">
                              {{ item.complain_limit }}
                            </small>
                          </div>
                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Produk yang dikomplain :</span
                            ><br />
                            <div
                              class="media"
                              v-for="(a, index) in item.product"
                              :key="index"
                            >
                              <div class="mt-1">
                                <a href="#" style="color: black">
                                  <img
                                    :src="a.image"
                                    class="img-thumbnail img-responsive"
                                    width="40px"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div class="media-body">
                                <div class="ml-2">
                                  <a href="#" style="color: black">
                                    <small class="mb-0" title="Ggg">
                                      {{ a.name }}
                                    </small>
                                    <br />
                                  </a>
                                  <small class="clsa"
                                    >{{ a.totalPriceCurrencyFormat }} </small
                                  ><br />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mb-3">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Bukti Komplain:</span
                            ><br />

                            <a
                              :href="getFullUrl(item.gambar1)"
                              style="color: black"
                              v-if="item.gambar1 != null"
                              target="_blank"
                            >
                              <img
                                :src="
                                  String(item.gambar1).includes(
                                    'https://jaja.id/'
                                  )
                                    ? addStr(item.gambar1, 8, 'jsonx.')
                                    : item.gambar1
                                "
                                width="80"
                                class="img-thumbnail img-responsive mt-2 mb-2"
                                alt=""
                              />
                            </a>

                            <br />
                            <a
                              :href="item.video"
                              style="color: black"
                              v-if="item.video != null"
                            >
                              <video
                                :src="item.video"
                                width="80"
                                class="img-thumbnail img-responsive mt-2 mb-2"
                              ></video>
                            </a>
                          </div>

                          <div
                            class="mb-3 bg-light border"
                            v-if="item.solusi != null"
                          >
                            <div class="p-2">
                              <span class="fsxx text-primary-jaja text-bold"
                                >Solusi : <br
                              /></span>
                              <small
                                class="fsxx clsa text-bold text-primary-jaja ml-2"
                                v-if="item.solusi == 'refund'"
                                >Pengembalian Dana <br
                              /></small>
                              <small
                                class="fsxx clsa text-bold text-primary-jaja ml-2"
                                v-if="item.solusi == 'lengkapi'"
                                >Lengkapi Barang <br
                              /></small>
                              <small
                                class="fsxx clsa text-bold text-primary-jaja ml-2"
                                v-if="item.solusi == 'change'"
                                >Tukar Barang <br
                              /></small>
                              <small
                                class="fsxx clsa ml-2"
                                v-if="item.catatan_solusi"
                                >Catatan : {{ item.catatan_solusi }}</small
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col mt-3">
                          <div class="mb-3 mt-5">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Syarat Pengembalian Dana:</span
                            >
                            <br />
                            <ol
                              class="small"
                              style="padding-left: 15px !important"
                            >
                              <li>
                                Pembeli harus mengirim barang ke alamat penjual
                                menggunakan jasa kurir terdekat
                              </li>
                              <li>
                                Pembeli harus menginput nomor resi pengiriman,
                                sebagai bukti barang telah dikirim
                              </li>
                              <li>
                                Setalah penjual menerima produk uang akan
                                diproses untuk dikembalikan ke rekening pembeli.
                                Silahkan tambahkan nomor rekening jika belum ada
                              </li>
                            </ol>
                          </div>
                        </div>
                        <div class="col mt-3">
                          <div class="mb-3 mt-5">
                            <span class="fsxx text-primary-jaja text-bold"
                              >Syarat Return Barang:</span
                            >
                            <br />
                            <ol
                              class="small"
                              style="padding-left: 15px !important"
                            >
                              <li>
                                Pembeli harus mengirim barang ke alamat penjual
                                menggunakan jasa kurir terdekat
                              </li>
                              <li>
                                Pembeli harus menginput nomor resi pengiriman,
                                sebagai bukti barang telah dikirim
                              </li>
                              <li>
                                Setalah penjual menerima produk, penjual akan
                                mengirim kembali barang yang sesuai
                              </li>
                              <li>
                                Setalah barang yg dikirim sampai, pembeli menkan
                                tombol terima pesanan
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      <section v-if="item.status === 'sendback'">
                        <button
                          class="btn-terima-barang"
                          @click="handleTerimaBarang"
                        >
                          Terima Barang
                        </button>
                      </section>
                      <div class="mt-3 row">
                        <div class="col mb-3">
                          <small class="fsxx text-danger"
                            >Note : biaya pengiriman ditanggung oleh pihak yang
                            mengirim</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="myModalKirimkanBarang">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title font-weight-bold">Kirimkan Barang</h5>
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <div v-if="isLoading" class="text-center">
                    <img
                      src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                      alt=""
                    />
                  </div>
                  <div v-else>
                    <form @submit.prevent="runKirimkanBarang">
                      <div
                        class="alert alert-dismissible fade show mt-2"
                        role="alert"
                        style="color: #6c757d; background-color: #d9f9ff"
                      >
                        <span class=""
                          >Kirimkan Barang Sesuai dengan Pesanan yang Dikomplain
                          !</span
                        ><br />
                      </div>
                      <div class="form-group">
                        <input
                          type="hidden"
                          class=""
                          id="id_data"
                          name="id_data"
                        />
                        <input
                          type="hidden"
                          class=""
                          :value="item.invoice"
                          id="invoice"
                          name="invoice"
                        />
                      </div>
                      <div class="form-group">
                        <p class="text-left">Masukkan Resi Pengiriman :</p>
                        <input
                          type="text"
                          class="form-control"
                          id="resi_customer"
                          v-model="resi_cust"
                          name="resi_customer"
                          placeholder="Masukkan Resi"
                          required
                        />
                      </div>
                      <div class="modal-footer">
                        <button
                          :disabled="isLoading"
                          type="button"
                          class="btn btn-secondary font-weight-bold"
                          data-dismiss="modal"
                          style="background: #fdb738; border-color: #fdb738"
                        >
                          Close
                        </button>
                        <button
                          :disabled="isLoading"
                          type="submit"
                          class="btn btn-primary font-weight-bold"
                          style="background: #64b0c9; border-color: #64b0c9"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script type="text/javascript" src="https://app.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      data: [],
      resi_cust: "",
      isLoading: false,
    };
  },
  components: {
    sideBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getDetailKomplain();
    window.scrollTo(0, 0);
  },
  methods: {
    getFullUrl(gambarUrl) {
      // Cek jika gambarUrl sudah mengandung prefix yang diinginkan
      if (gambarUrl && gambarUrl.startsWith("https://jaja.id/")) {
        return (
          "https://jsonx.jaja.id/" + gambarUrl.split("https://jaja.id/")[1]
        );
      }
      return gambarUrl;
    },
    addStr(url, index, str) {
      // Implementasi fungsi addStr jika diperlukan
      return url; // Sesuaikan dengan logika yang Anda butuhkan
    },
    handleTerimaBarang() {
      Swal.fire({
        title: "Konfirmasi",
        text: "Anda yakin telah menerima produk pesanan dengan baik?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          this.terimaBarang();
        }
      });
    },
    terimaBarang() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=7vgloal55kn733tsqch0v7lh1tfrcilq");

      // query string dari data yang ingin dikirim
      const params = new URLSearchParams({
        invoice: this.$route.params.id,
      });

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        mode: "cors",
      };

      fetch(
        `https://jaja.id/backend/order/terimaBarangByCustomer?${params.toString()}`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((res) => {
          this.isLoading = false;
          if (res.status.code === 200) {
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                this.getDetailKomplain();
              });
          } else {
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {
                this.getDetailKomplain();
              });
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          this.$swal.fire(
            "Error",
            `Something went wrong: ${error.message}`,
            "error"
          );
        });
    },

    getDetailKomplain() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}order/komplainDetail?invoice=${this.$route.params.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.data = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    kirimkanBarangModal() {
      $("#myModalKirimkanBarang").modal("show");
    },
    runKirimkanBarang() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=7vgloal55kn733tsqch0v7lh1tfrcilq");

      // Membangun query string dari data yang ingin dikirim
      const params = new URLSearchParams({
        date_resi_cust: "",
        id_data: "",
        invoice: this.$route.params.id,
        resi_customer: this.resi_cust,
      });

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        mode: "cors",
      };

      fetch(
        `https://jaja.id/backend/order/inputResiCustomer?${params.toString()}`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((res) => {
          this.isLoading = false;
          if (res.status.code === 200) {
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                this.getDetailKomplain();
                $("#myModalKirimkanBarang").modal("hide"); // Tutup modal setelah alert OK
              });
          } else {
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {
                this.getDetailKomplain();
              });
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          this.$swal.fire(
            "Error",
            `Something went wrong: ${error.message}`,
            "error"
          );
        });
    },

    // runKirimkanBarang() {
    //   var myHeaders = new Headers();
    //   myHeaders.append("Authorization", this.globalToken);
    //   myHeaders.append("Content-Type", "application/json"); // Menambahkan Content-Type
    //   myHeaders.append("Cookie", "ci_session=7vgloal55kn733tsqch0v7lh1tfrcilq");

    //   var raw = JSON.stringify({
    //     date_resi_cust: null,
    //     id_data: null,
    //     invoice: this.$route.params.id,
    //     resi_customer: this.resi_cust,
    //   });

    //   var requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: "follow",
    //     // mode: "cors", // Menambahkan mode CORS
    //   };

    //   fetch(
    //     `https://jaja.id/backend/order-komplain/get-komplain`,
    //     requestOptions
    //   )
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }
    //       return response.json();
    //     })
    //     .then((res) => {
    //       if (res.status.code === 200) {
    //         this.isLoading = false;
    //         this.$swal
    //           .fire("Sukses", res.status.message, "success")
    //           .then(() => {
    //             this.getDetailKomplain();
    //           });
    //       } else {
    //         this.$swal
    //           .fire("Warning", res.status.message, "warning")
    //           .then(() => {
    //             this.getDetailKomplain();
    //           });
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Fetch error:", error);
    //       this.$swal.fire(
    //         "Error",
    //         `Something went wrong: ${error.message}`,
    //         "error"
    //       );
    //     });
    // },
    addStr(str, index, stringToAdd) {
      return (
        str.substring(0, index) + stringToAdd + str.substring(index, str.length)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.iconPayment {
  width: 50%;
  margin-right: 5%;
}
.mx-datepicker {
  width: 100%;
}
.parent-card-body-danakembali {
  border-radius: 0 0 10px 10px;
}
.card-header-danakembali {
  background: rgb(100, 175, 200);
  border-radius: 10px 10px 0 0;
}

.card-body-danakembali {
  width: 100%;
  height: auto;
  border: 1px solid rgb(100, 175, 200);
  border-radius: 10px;
}

.kotak-profil-1 {
  margin-right: 15%;
}
.bg-jaja {
  background: rgb(100, 175, 200);
}

.step-indicator {
  /* margin-top: 50px; */
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.step-indicator .step-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #c2c2c2;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  position: relative;
  line-height: 50px;
  font-size: 20px;
}

.step.active .step-icon {
  background: #53d1b6;
}

.step p {
  text-align: center;
  position: absolute;
  bottom: -50px;
  color: #c2c2c2;
  font-size: 12px;
  /* font-weight: bold; */
}

.step.active p {
  color: #53d1b6;
}

.step.step2 p,
.step.step3 p {
  left: 50%;
  transform: translateX(-50%);
}

.indicator-line {
  width: 100%;
  height: 2px;
  background: #c2c2c2;
  flex: 1;
}

.indicator-line.active {
  background: #53d1b6;
}
.btn-terima-barang {
  background-color: #28a745; /* Green */
  color: white;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
}

.btn-terima-barang:hover {
  background-color: #218838; /* Darker green */
}
</style>