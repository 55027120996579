<template>
  <div>
    <div v-if="isLoading" class="overlay">
      <img
        src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
        alt="Loading"
        class="loading-gif"
      />
    </div>
    <div class="d-none d-lg-block body-home" v-if="!isLoading">
      <alert-image v-if="showAlertImage" />
      <section class="body-home">
        <div>
          <!-- BANNER -->
          <div
            class="container"
            v-if="homeGetters.banner"
            style="margin-top: -50px; margin-bottom: 40px; height: 400px"
          >
            <carousel-3d
              :perspective="0"
              :space="400"
              :display="3"
              :autoplay="true"
              :autoplay-timeout="3000"
              style="height: 400px"
            >
              <slide
                v-for="(item, i) in homeGetters.banner"
                v-bind:key="item.id"
                :index="i"
                style="width: 900px; height: auto"
              >
                <v-lazy-image style="height: 350px" :src="item.image" />
              </slide>
            </carousel-3d>
          </div>
          <content-placeholders class="container" :centered="true" v-else>
            <content-placeholders-img style="width: 819px; height: 400px" />
          </content-placeholders>

          <div class="custom-background">
            <div
              style="padding-top: 15px; padding-bottom: 15px"
              class="container"
            >
              <div class="row justify-content-center">
                <div
                  v-for="(item, index) in homeGetters.categoryChoice.slice(
                    0,
                    12
                  )"
                  :key="index"
                  class="col mt-4"
                  style="
                    flex-basis: calc(100% / 6);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <router-link :to="'/kategori/' + item.slug">
                    <div
                      style="
                        display: flex;
                        width: 80px;
                        height: 80px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        border-radius: 180px;
                        background: var(--Neutral-10, #fff);
                      "
                    >
                      <img
                        v-if="!loading"
                        :src="item.icon"
                        style="width: 60px; height: 60px; flex-shrink: 0"
                      />
                      <div
                        v-else
                        class="skeleton-box"
                        style="width: 60px; height: 60px"
                      ></div>
                    </div>
                  </router-link>
                  <router-link :to="'/kategori/' + item.slug">
                    <p
                      v-if="!loading"
                      class="mt-2"
                      style="
                        color: #fff;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px; /* 114.286% */
                        text-align: center;
                      "
                    >
                      {{ truncateText(item.name, 1) }}
                    </p>
                    <div
                      v-else
                      class="skeleton-box mt-2"
                      style="width: 80px; height: 16px"
                    ></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex"></div>

          <div class="banner">
            <div class="section left-section">
              <div class="content">
                <img
                  src="../../assets/navbar-asset/masdis.png"
                  alt="Master Diskon Logo"
                  class="logo"
                />
                <div class="text-container">
                  <p class="subtitle">Kamu suka Travelling?</p>
                  <p class="title">Master Diskon Aja!</p>
                  <button
                    @click="redirectToMasterDiskon"
                    class="ml-5 mt-2"
                    style="
                      background: none;
                      border-radius: 4px;
                      border: 1px solid var(--Neutral-10, #2c3e50);
                      display: flex;
                      width: 100px;
                      padding: 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                      color: var(--Neutral-10, #2c3e50);
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 20px; /* 142.857% */
                    "
                  >
                    Cek Disini
                  </button>
                </div>
              </div>
            </div>
            <div class="section right-section">
              <div class="content">
                <img
                  src="../../assets/navbar-asset/ebh.png"
                  alt="Eureka Bookhouse Logo"
                  class="logo"
                />
                <div class="text-container">
                  <p style="color: white" class="subtitle">
                    Mau cari buku? Yuk cek
                  </p>
                  <p style="color: white" class="title">Eureka Bookhouse</p>
                  <button
                    @click="redirectEbh"
                    class="mr-5 mt-2"
                    style="
                      background: none;
                      border-radius: 4px;
                      border: 1px solid var(--Neutral-10, #fff);
                      display: flex;
                      width: 100px;
                      padding: 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                      color: var(--Neutral-10, #fff);
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 20px; /* 142.857% */
                    "
                  >
                    Cek Disini
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- PRODUK TOKO GT -->
          <div class="mt-5 container">
            <div class="d-flex justify-content-between">
              <div>
                <h5 style="color: #65b0c9" class="font-weight-bold mt-1 ml-2">
                  General Trading Product
                </h5>
              </div>
              <div>
                <router-link :to="'/home/DetailToko/general-trading-eureka'">
                  <h5 style="color: #65b0c9" class="mt-1">Lihat Semua ></h5>
                </router-link>
              </div>
            </div>
            <br />
            <div
              class="m-1 row"
              style="background-color: white; border-radius: 10px"
            >
              <div class="col-4" style="padding: 0">
                <div class="main-banner">
                  <router-link :to="'/home/DetailToko/general-trading-eureka'">
                    <img src="../../assets/bg/bnnr-gt.jpg" alt="Main Banner" />
                  </router-link>
                </div>
              </div>
              <div class="col-8">
                <div class="ml-1 products">
                  <div
                    style="position: relative"
                    v-for="(item, index) in items.slice(0, 8)"
                    :key="index"
                    class="product-container"
                  >
                    <div>
                      <img
                        style="
                          position: absolute;
                          left: 0;
                          width: 100px;
                          top: 16px;
                        "
                        src="../../assets/icon-s/promo-badge.png"
                      />
                    </div>
                    <div
                      style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
                      class="product mt-3"
                    >
                      <router-link :to="'/home/DetailProduk/' + item.slug">
                        <img
                          :src="item.image"
                          alt="Product Image"
                          class="mt-3 product-image"
                        />
                        <p
                          style="text-align: left"
                          class="ml-2 mt-3 product-name"
                        >
                          {{
                            item.name.length > 20
                              ? item.name.slice(0, 20).concat("...")
                              : item.name
                          }}
                        </p>
                      </router-link>
                      <div class="d-flex justify-content-between">
                        <div>
                          <span class="ml-2 product-discount">{{
                            item.price
                          }}</span>
                        </div>
                        <div></div>
                        <div>
                          <span
                            style="
                              border-radius: 2px;
                              border: 0.5px solid var(--Primary-50, #438196);
                              background: var(--Primary-10, #f3fcff);
                              padding: 0px 2px;
                              gap: 8px;
                              color: var(--Primary-50, #438196);
                              font-family: Poppins;
                              font-size: 10px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 16px; /* 200% */
                              letter-spacing: -0.08px;
                            "
                            class="mr-2"
                            >Special Price</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PRODUK PENCARIAN POPULER -->
          <div class="mt-5 container" v-if="homeGetters.basedOnSearch">
            <h5
              style="color: #65b0c9"
              v-if="homeGetters.trending.length > 0"
              class="d-flex font-weight-bold mt-1 ml-2"
            >
              Paling Popular
            </h5>

            <flickity
              v-if="homeGetters.trending"
              ref="flickity"
              class="mt-3 flickity"
              :options="flickityOptions"
            >
              <div
                v-for="(item, index) in homeGetters.trending"
                :key="index"
                style="display: flex; align-items: stretch; margin: 10px"
              >
                <router-link
                  :to="'/home/DetailProduk/' + item.slug"
                  style="display: flex"
                >
                  <div>
                    <img
                      :src="
                        String(item.image).includes('/https:')
                          ? item.image.slice(45, item.image.length)
                          : item.image
                      "
                      style="
                        width: 160px;
                        height: 160px;
                        border-radius: 12px 0px 0px 12px;
                        background: url(<path-to-image>) lightgray 50% / cover
                            no-repeat,
                          #d9d9d9;
                      "
                    />
                  </div>
                  <div
                    class="shadow-custom"
                    style="
                      display: flex;
                      padding: 10px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: flex-start;
                      gap: 10px;
                      align-self: stretch;
                      border-radius: 0px 12px 12px 0px;
                      background: var(--Neutral-10, #fff);
                      width: 200px;
                    "
                  >
                    <span
                      class="text-left"
                      style="
                        color: var(--Neutral-60, #1a1a1a);
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px; /* 137.5% */
                        letter-spacing: -0.16px;
                        width: 100%;
                      "
                    >
                      {{
                        item.name.length > 20
                          ? item.name.slice(0, 20).concat("...")
                          : item.name
                      }}
                    </span>
                    <span
                      style="
                        color: var(--Secondary-30, #fdb739);
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 142.857% */
                        letter-spacing: -0.14px;
                      "
                    >
                      {{ item.totalSeen }}+Dicari
                    </span>
                  </div>
                </router-link>
              </div>
            </flickity>
            <cardPlaceholder v-else />
          </div>
          <!-- PRODUK TRANDING -->
          <div class="mt-5 container" v-if="homeGetters.trending">
            <h5
              style="color: #65b0c9"
              v-if="homeGetters.trending.length > 0"
              class="d-flex font-weight-bold mt-1 ml-2"
            >
              Rekomendasi Untuk Anda
            </h5>

            <flickity
              v-if="homeGetters.basedOnSearch"
              ref="flickity"
              class="mt-4 flickity"
              :options="flickityOptions"
            >
              <div
                style="padding-right: 20px"
                v-for="(item, index) in homeGetters.basedOnSearch"
                :key="index"
              >
                <router-link :to="'/home/DetailProduk/' + item.slug">
                  <div
                    class="shadow-custom"
                    style="
                      display: flex;
                      flex-direction: column;
                      width: 232px;
                      border-radius: 12px;
                      overflow: hidden;
                      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                    "
                  >
                    <img
                      :src="
                        String(item.image).includes('/https:')
                          ? item.image.slice(45, item.image.length)
                          : item.image
                      "
                      style="
                        width: 100%;
                        height: 232px;
                        object-fit: cover;
                        border-radius: 12px 12px 0 0;
                      "
                    />
                    <div :class="item.isDiscount ? `btn-preordor` : `d-none`">
                      <span
                        class="bgdiskons font-weight-bold"
                        style="
                          position: absolute;
                          left: 0;
                          top: -232px;
                          /* margin-top: -232px;
                    margin-left: 99px; */
                        "
                      >
                        {{ item.discount }} %
                      </span>
                    </div>

                    <div
                      :class="
                        item.isPreorder == 'Y' ? `btn-wishlist2` : `d-none`
                      "
                    >
                      <span
                        style="position: absolute; right: 0"
                        class="bgpreorder font-weight-bold"
                      >
                        Pre Order
                      </span>
                    </div>

                    <div
                      :class="
                        item.freeOngkir == 'Y' ? `btn-wishlist3` : `d-none`
                      "
                    >
                      <span
                        style="position: absolute; left: 0; top: -232px"
                        class="bgfreeongkir font-weight-bold"
                      >
                        Free Ongkir
                      </span>
                    </div>
                    <div
                      style="
                        padding: 10px;
                        background-color: #fff;
                        border-radius: 0 0 12px 12px;
                      "
                    >
                      <p
                        class="text-left"
                        style="
                          color: #1a1a1a;
                          font-family: Poppins;
                          font-size: 14px;
                          font-weight: 500;
                          line-height: 20px;
                          letter-spacing: -0.14px;
                          margin-bottom: 10px;
                          white-space: nowrap; /* Menambahkan ini untuk memastikan satu baris */
                        "
                      >
                        {{
                          item.name.length > 25
                            ? item.name.slice(0, 25).concat("...")
                            : item.name
                        }}
                      </p>

                      <p
                        class="text-left"
                        style="
                          color: #000;
                          font-family: Poppins;
                          font-size: 16px;
                          font-weight: 600;
                          line-height: 22px;
                          letter-spacing: -0.16px;
                          margin-bottom: 10px;
                        "
                      >
                        {{ item.isDiscount ? item.priceDiscount : item.price }}
                        <strike :class="item.isDiscount ? `` : `d-none`">
                          <small>{{ item.price }}</small>
                        </strike>
                      </p>
                      <p
                        class="text-left"
                        style="
                          color: #ca8c1e;
                          font-family: Poppins;
                          font-size: 10px;
                          font-weight: 500;
                          line-height: 16px;
                          letter-spacing: -0.1px;
                        "
                      >
                        <i
                          class="fas fa-map-marker-alt"
                          style="margin-right: 4px; color: red"
                        ></i>
                        {{ item.location }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </flickity>
            <cardPlaceholder v-else />
          </div>
          <!-- PENGIRIMAN BARU -->
          <div class="mt-5 custom-background-ship">
            <div
              style="padding-top: 40px; padding-bottom: 40px"
              class="container"
            >
              <div class="row justify-content-center">
                <div
                  class="col"
                  style="
                    flex-basis: calc(100% / 4);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                  "
                >
                  <div
                    style="
                      display: flex;
                      width: 80px;
                      height: 80px;
                      padding: 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                      border-radius: 80px;
                      background: var(--Neutral-10, #fff);
                    "
                  >
                    <img
                      style="width: 60px; height: 31.7px; flex-shrink: 0"
                      src="../../assets/icon-s/cepat.png"
                    />
                  </div>
                  <span
                    style="
                      color: var(--Neutral-60, #1a1a1a);
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: italic;
                      font-weight: 600;
                      line-height: 26px; /* 130% */
                      letter-spacing: 0.2px;
                    "
                  >
                    Pengiriman Cepat
                  </span>
                </div>
                <div
                  class="col"
                  style="
                    flex-basis: calc(100% / 4);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                  "
                >
                  <div
                    style="
                      display: flex;
                      width: 80px;
                      height: 80px;
                      padding: 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                      border-radius: 80px;
                      background: var(--Neutral-10, #fff);
                    "
                  >
                    <img
                      style="width: 50px; height: 50px; flex-shrink: 0"
                      src="../../assets/icon-s/free.png"
                    />
                  </div>
                  <span
                    style="
                      color: var(--Neutral-60, #1a1a1a);
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: italic;
                      font-weight: 600;
                      line-height: 26px; /* 130% */
                      letter-spacing: 0.2px;
                    "
                  >
                    Gratis Ongkir
                  </span>
                </div>
                <div
                  class="col"
                  style="
                    flex-basis: calc(100% / 4);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                  "
                >
                  <div
                    style="
                      display: flex;
                      width: 80px;
                      height: 80px;
                      padding: 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                      border-radius: 80px;
                      background: var(--Neutral-10, #fff);
                    "
                  >
                    <img
                      style="width: 42.449px; height: 50.5px; flex-shrink: 0"
                      src="../../assets/icon-s/aman.png"
                    />
                  </div>
                  <span
                    style="
                      color: var(--Neutral-60, #1a1a1a);
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: italic;
                      font-weight: 600;
                      line-height: 26px; /* 130% */
                      letter-spacing: 0.2px;
                    "
                  >
                    Pembayaran Aman
                  </span>
                </div>
                <div
                  class="col"
                  style="
                    flex-basis: calc(100% / 4);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                  "
                >
                  <div
                    style="
                      display: flex;
                      width: 80px;
                      height: 80px;
                      padding: 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                      border-radius: 80px;
                      background: var(--Neutral-10, #fff);
                    "
                  >
                    <img
                      style="width: 46px; height: 60.25px; flex-shrink: 0"
                      src="../../assets/icon-s/ori.png"
                    />
                  </div>
                  <span
                    style="
                      color: var(--Neutral-60, #1a1a1a);
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: italic;
                      font-weight: 600;
                      line-height: 26px; /* 130% */
                      letter-spacing: 0.2px;
                    "
                  >
                    Dijamin 100% Ori
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- KATEGORI STATIS BARU -->
          <div class="mt-5 container">
            <h5
              style="color: #65b0c9"
              class="d-flex font-weight-bold mt-1 ml-2"
            >
              Sedang Tren
            </h5>

            <div class="mt-4 row">
              <div class="col-3">
                <router-link :to="'/kategori/musics'">
                  <img
                    style="width: 300px"
                    src="../../assets/icon-s/music-com.png"
                  />
                </router-link>
              </div>
              <div class="col-3">
                <router-link :to="'/kategori/sports'">
                  <img
                    style="width: 300px"
                    src="../../assets/icon-s/sport-com.png"
                  />
                </router-link>
              </div>
              <div class="col-3">
                <router-link :to="'/kategori/games'">
                  <img
                    style="width: 300px"
                    src="../../assets/icon-s/games-com.png"
                  />
                </router-link>
              </div>
              <div class="col-3">
                <!-- <router-link :to="'/kategori/voucher'">
                  <img
                    style="width: 300px"
                    src="../../assets/icon-s/voucher-com.png"
                  />
                </router-link> -->
                <a href="https://devuvos.uvcr.me/uvosjajaid" target="_blank">
                  <img
                    style="width: 300px"
                    src="../../assets/icon-s/voucher-com.png"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- REKOMENDASI PRODUK -->
          <div class="mt-5 container">
            <h5
              style="color: #65b0c9"
              class="d-flex font-weight-bold mt-1 ml-2"
            >
              Mungkin anda suka
            </h5>

            <cardnonSlider class="mt-4" :data="recommendedGetters.items" />
          </div>
          <!-- PRODUK FLASHSALE -->
          <!-- <div v-if="homeGetters.flashSale">
      <h5 class="d-flex font-weight-bold mt-1 ml-2 mb-2" v-if="homeGetters.flashSale.length > 0">
        Flash Sale
      </h5>
      <flickity v-if="homeGetters.flashSale" ref="flickity" class="flickity" :options="flickityOptions">
        <div v-for="(item, index) in homeGetters.flashSale" :key="index" class="carousel-cell mb-4"
          style="padding-left: 10px; padding-top: 10px">
          <router-link :to="'/home/DetailProduk/' + item.slug">
            <div class="d-flex mb-3 mr-3 p-3 shadow-custom rounded"
              style="background: white; border-radius: 20px !important">
              <div class="bd-highlight" style="background: white; border-radius: 20px">
                <div class="kotak-img-populer">
                  <img class="image-populer p-2" :src="
                                                  String(item.image).includes('/https:')
                          ? item.image.slice(45, item.image.length)
                          : item.image" alt="Card image cap" style="max-height: 100%;
                                      max-width: 100%;
                                      width: auto;
                                      border-radius: 15px;
                                    " />
                </div>
              </div>
              <div class="bd-highlight">
                <div class="kotak-text-populer">
                  <div class="p-2">
                    <h5 class="judul-populer d-flex">
                      {{
                      item.name.length > 13
                      ? item.name.slice(0, 50)
                      : item.name
                      }}
                    </h5>
                    <div class="harga-dan-loc-populer">
                      <p class="">{{ item.priceDiscount }}</p>
                      <p style="text-decoration: line-through red;">
                        {{ item.price }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </flickity>
      <cardPlaceholder v-else />
    </div> -->

          <br />
        </div>
      </section>
    </div>
    <div
      class="d-lg-none home bg-dasar-none"
      style="background-color: #f0f0f0; margin-top: -180px"
    >
      <!-- NAVBAR MOBILE -->
      <div>
        <NavBarMobile />
      </div>
      <div>
        <b-carousel
          id="carousel-fade"
          style="text-shadow: 0px 0px 2px #000"
          indicators
          fade
          img-width="1024"
          img-height="480"
          class="center-indicators"
        >
          <b-carousel-slide
            v-for="item in homeGetters.banner"
            :key="item.id"
            :img-src="item.image"
          ></b-carousel-slide>
        </b-carousel>
      </div>
      <br />
      <!-- KATEGORI DINAMIS MOBILE -->
      <div style="background: var(--Secondary-30, #65b0c9)">
        <div class="con-mob">
          <div class="con-mob">
            <div class="row">
              <div
                v-for="(item, index) in homeGetters.categoryChoice.slice(0, 12)"
                :key="index"
                class="col-2"
              >
                <router-link :to="'/kategori/' + item.slug">
                  <div
                    style="
                      border-radius: 48px;
                      background: var(--Neutral-10, #fff);
                      display: flex;
                      width: 48px;
                      height: 48px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                    "
                  >
                    <img
                      :src="item.icon"
                      style="width: 36px; height: 36px; flex-shrink: 0"
                    />
                  </div>
                  <p
                    class="mt-2"
                    style="
                      color: var(--Neutral-10, #fff);
                      font-size: 9px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 15px; /* 166.667% */
                      letter-spacing: -0.09px;
                    "
                  >
                    {{ truncateText(item.name, 1) }}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- KATEGORI STATIS BARU -->
      <div class="mt-2 con-mob">
        <span class="d-flex title-mobile"> Category </span>

        <div class="mt-4 d-flex" style="justify-content: center">
          <div class="mr-3">
            <router-link :to="'/kategori/musics'">
              <img style="width: 87px" src="../../assets/icon-s/music-m.png" />
            </router-link>
          </div>
          <div class="mr-3">
            <router-link :to="'/kategori/sports'">
              <img style="width: 87px" src="../../assets/icon-s/sport-mm.png" />
            </router-link>
          </div>
          <div class="mr-3">
            <router-link :to="'/kategori/games'">
              <img style="width: 87px" src="../../assets/icon-s/sport-m.png" />
            </router-link>
          </div>
          <div class="mr-3">
            <!-- <router-link :to="'/kategori/voucher'">
              <img
                style="width: 87px"
                src="../../assets/icon-s/voucher-m.png"
              />
            </router-link> -->
            <a href="https://devuvos.uvcr.me/uvosjajaid" target="_blank">
              <img
                style="width: 87px"
                src="../../assets/icon-s/voucher-m.png"
              />
            </a>
          </div>
        </div>
      </div>

      <!-- Pencarian Popular  Mobile -->
      <div class="mt-2 con-mob">
        <span class="d-flex title-mobile"> Paling Popular </span>

        <flickity
          v-if="homeGetters.trending"
          ref="flickity"
          class="mt-3 flickity"
          :options="flickityOptions"
        >
          <div
            v-for="(item, index) in homeGetters.trending"
            :key="index"
            style="display: flex; align-items: stretch; margin: 10px"
          >
            <router-link
              :to="'/home/DetailProduk/' + item.slug"
              style="display: flex"
            >
              <div>
                <img
                  :src="
                    String(item.image).includes('/https:')
                      ? item.image.slice(45, item.image.length)
                      : item.image
                  "
                  style="
                    width: 80px;
                    height: 80px;
                    border-radius: 12px 0px 0px 12px;
                    background: url(<path-to-image>) lightgray 50% / cover
                        no-repeat,
                      #d9d9d9;
                  "
                />
              </div>
              <div
                class="shadow-custom"
                style="
                  height: 80px;
                  display: flex;
                  padding: 10px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 10px;
                  align-self: stretch;
                  border-radius: 0px 12px 12px 0px;
                  background: var(--Neutral-10, #fff);
                  width: 150px;
                "
              >
                <span
                  class="text-left"
                  style="
                    color: var(--Primary-40, #65b0c9);
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 160% */
                    letter-spacing: -0.1px;
                  "
                >
                  {{
                    item.name.length > 20
                      ? item.name.slice(0, 20).concat("...")
                      : item.name
                  }}
                </span>
                <span
                  style="
                    color: var(--Secondary-30, #fdb739);

                    font-size: 8px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px; /* 175% */
                    letter-spacing: 0.08px;
                  "
                >
                  {{ item.totalSeen }}+Dicari
                </span>
              </div>
            </router-link>
          </div>
        </flickity>
        <cardPlaceholder v-else />
      </div>
      <div class="mt-2 con-mob">
        <span class="d-flex title-mobile"> Mungkin Anda Suka </span>

        <cardnonSlider class="mt-4" :data="recommendedGetters.items" />
      </div>

      <div class="mt-5">
        <br />
        <NavBar />
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import AlertImage from "@/components/AlertImage.vue";
// import axios from "axios";
import cardnonSlider from "../../components/card/cardnonslider.vue";
import cardPlaceholder from "../../components/card/cardplaceholder";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Carousel3d, Slide } from "vue-carousel-3d";
import VLazyImage from "v-lazy-image/v2";
import NavBar from "../master/navbarbuttom.vue";
import NavBarMobile from "../master/navbarmobile.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [],
      isLoading: false,
      loading: true,
      showAlertImage: true,
      slides: 7,
      slide: 0,
      sliding: null,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true, // Menampilkan tombol geser kiri-kanan
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      flickityOptionsKategory: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
    };
  },
  components: {
    Flickity,
    cardnonSlider,
    AlertImage,
    Carousel3d,
    Slide,
    cardPlaceholder,
    VLazyImage,
    NavBar,
    NavBarMobile,
  },
  mounted() {
    this.showAlertImage = false;
    this.fetchData();
    // console.log('axios.defaults.baseURL', axios.defaults.baseURL)
  },
  computed: {
    ...mapGetters({
      globalToken: "globalTokenGetters",
    }),
    recommendedGetters() {
      return this.$store.getters.recommendedGetters;
    },
    homeGetters() {
      return this.$store.getters.homeGetters;
    },
  },
  created() {
    setTimeout(() => {
      this.isLoading = false; // set isLoading to false when data is ready
    }, 2000); // change 2000 to the actual data loading time
    this.loadData();
  },
  methods: {
    truncateName(name, wordLimit) {
      const words = name.split(" ");
      if (words.length <= wordLimit) return name;
      return words.slice(0, wordLimit).join(" ") + "...";
    },
    fetchData() {
      const url =
        "https://jaja.id/backend/product/store/general-trading-eureka?page=1&limit=12&keyword=&filter_price=&filter_category=&filter_condition=&filter_preorder=&filter_brand=&sort=&filter_etalase=";

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.status.code === 200) {
            this.items = data.data.items;
          } else {
            console.error("Error fetching data:", data.status.message);
          }
        })
        .catch((error) => console.error("Error:", error));
    },

    loadData() {
      // Simulasi loading data
      setTimeout(() => {
        // Setelah data dimuat, set loading ke false
        this.loading = false;
      }, 2000); // simulasi delay 2 detik
    },
    redirectToMasterDiskon() {
      window.open("https://masterdiskon.com/", "_blank");
    },
    redirectEbh() {
      window.open("https://www.eurekabookhouse.co.id/", "_blank");
    },
    truncateText(text, wordLimit) {
      if (!text) return "";
      const words = text.split(" ");
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(" ") + "...";
      }
      return text;
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sassy+Frass&display=swap");
.carousel-3d-slide.current {
  border-radius: 10px;
}
.carousel-3d-slide.right-1 {
  border-radius: 10px;
}
.carousel-3d-slide.left-1 {
  border-radius: 10px;
}

.box-header-slider {
  margin-top: 4%;
}

.box-slider {
  height: 350px;
  /* margin-top: 20px;
      margin-bottom: 20px; */
}

.body-home {
  /* jaja */
  /* background: #eff6f8 */
  /* ebh */
  background: #f2f2f2;
}
.image-banner {
  height: 20%;
  width: 10%;
  object-fit: contain;
}

.card-category-banner {
  margin-left: 20px;
}

.card-category-testing {
  margin-right: 20px;
  width: 260px;
  height: 90px;
  border: 3px solid #a0cadb;
  border-radius: 10px;
}

.card-category-testing div p {
  color: #65b0c9;
}

.card-body-category {
  border-radius: 10px;
}

.card-category {
  /* width:250px; */
  margin-left: 20px;
}
.body-keunggulan {
  position: relative;
}
.body-keunggulan .bungkus-icon {
  position: absolute;
  top: -12px;
}
.bungkus-icon span {
  border: 4px #ffc107 solid !important;
}
.body-keunggulan .bungkus-text {
  /* position:absolute; */
  margin-left: 30px;
  /* width:200px; */
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
}
/* PHONE */
@media (max-width: 600px) {
  .body-keunggulan .bungkus-text {
    /* position:absolute; */
    margin-left: 30px;
    /* width:200px; */
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
  }
  .banner-jaja {
    width: 519px;
    height: auto;
    border-radius: 20px !important;
  }
  .banner-jaja-id {
    width: 519px;
  }
  .kotak-img {
    height: 100px;
    width: 50px;
  }

  .kotak-img img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 180%;
    object-fit: cover;
  }

  .card-rekom {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border: 0.1px;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }

  .kotak-img-rekom {
    /* background:yellow; */
    height: 60%;
    width: 90%;
    align-self: center;
    padding: 10px 0;
  }

  .kotak-img-rekom img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* margin-left:20px; */
  }

  .kotak-body-rekom {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    height: 40%;
    border-radius: 0 0 20px 20px;
  }

  .title-card {
    width: 9%;
  }

  .judul-tranding {
    font-size: 5px;
    color: #65b0c9;
    font-weight: bold;
  }

  .card-tranding {
    /* border-radius: 5% 5% 0 0; */
    border-radius: 20px;
  }
}
/* PHONE AND TABLET */
@media (max-width: 600px) {
  .body-keunggulan .bungkus-text {
    /* position:absolute; */
    margin-left: 30px;
    /* width:200px; */
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
  }
  .banner-jaja {
    width: 619px;
    border-radius: 20px !important;
  }

  .banner-jaja-id {
    width: 619px;
  }
  .kotak-img {
    height: 120px;
    width: 60px;
    justify-content: center;
  }

  .kotak-img img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .card-rekom {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border: 0.1px;
    width: 130px;
    height: 110px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }

  .kotak-img-rekom {
    /* background:yellow; */
    height: 60%;
    width: 90%;
    align-self: center;
    padding: 10px 0;
  }

  .kotak-img-rekom img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* margin-left:20px; */
  }

  .kotak-body-rekom {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    height: 40%;
    border-radius: 0 0 20px 20px;
  }

  .title-card {
    width: 90%;
  }

  .judul-tranding {
    font-size: 7px;
    color: #65b0c9;
    font-weight: bold;
  }

  .card-tranding {
    /* border-radius: 5% 5% 0 0; */
    border-radius: 20px;
  }

  .totalcari-tranding {
    color: #fdb738;
  }

  .totalcari-rekom {
    color: #fdb738 !important;
  }
}
/* TABLET */
@media (max-width: 768px) {
  .body-keunggulan .bungkus-text {
    /* position:absolute; */
    margin-left: 15px;
    /* width:200px; */
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
  }
  .banner-jaja {
    width: 719px;
    border-radius: 20px !important;
  }

  .banner-jaja-id {
    width: 719px;
  }
  .kotak-img {
    height: 140px;
    width: 70px;
    justify-content: center;
  }

  .kotak-img img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .card-rekom {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border: 0.1px;
    width: 215px;
    height: 325px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }

  .kotak-img-rekom {
    /* background:yellow; */
    height: 60%;
    width: 90%;
    align-self: center;
    padding: 10px 0;
  }

  .kotak-img-rekom img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* margin-left:20px; */
  }

  .kotak-body-rekom {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    height: 40%;
    border-radius: 0 0 20px 20px;
  }

  .title-card {
    width: 90%;
  }

  .judul-tranding {
    font-size: 9px;
    color: #65b0c9;
    font-weight: bold;
  }

  .card-tranding {
    /* border-radius: 5% 5% 0 0; */
    border-radius: 20px;
  }

  .totalcari-tranding {
    color: #fdb738;
  }

  .totalcari-rekom {
    color: #fdb738 !important;
  }
}
/* LAPTOP */
@media (max-width: 992px) {
  .body-keunggulan .bungkus-text {
    /* position:absolute; */
    margin-left: 20px;
    /* width:200px; */
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
  }
  .banner-jaja {
    width: 819px;
    border-radius: 20px !important;
  }

  .banner-jaja-id {
    width: 819px;
  }
  .kotak-img {
    height: 160px;
    width: 80px;
    justify-content: center;
  }

  .kotak-img img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .card-rekom {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border: 0.1px;
    width: 215px;
    height: 325px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }

  .kotak-img-rekom {
    /* background:yellow; */
    height: 60%;
    width: 90%;
    align-self: center;
    padding: 10px 0;
  }

  .kotak-img-rekom img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* margin-left:20px; */
  }

  .kotak-body-rekom {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    height: 40%;
    border-radius: 0 0 20px 20px;
  }

  .title-card {
    width: 90%;
  }

  .judul-tranding {
    font-size: 11px;
    color: #65b0c9;
    font-weight: bold;
  }

  .card-tranding {
    /* border-radius: 5% 5% 0 0; */
    border-radius: 20px;
  }

  .totalcari-tranding {
    color: #fdb738;
  }

  .totalcari-rekom {
    color: #fdb738 !important;
  }
}
/* DEKSTOP */
@media (max-width: 1200px) {
  .body-keunggulan .bungkus-text {
    /* position:absolute; */
    margin-left: 20px;
    /* width:200px; */
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
  }
  .banner-jaja {
    width: 819px;
    border-radius: 20px !important;
  }

  .banner-jaja-id {
    width: 819px;
  }
  .kotak-img {
    height: 180px;
    width: 90px;
    justify-content: center;
  }

  .kotak-img img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .card-rekom {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border: 0.1px;
    width: 215px;
    height: 325px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }

  .kotak-img-rekom {
    /* background:yellow; */
    height: 60%;
    width: 90%;
    align-self: center;
    padding: 10px 0;
  }

  .kotak-img-rekom img {
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* margin-left:20px; */
  }

  .kotak-body-rekom {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    height: 40%;
    border-radius: 0 0 20px 20px;
  }

  .title-card {
    width: 90%;
  }

  .judul-tranding {
    font-size: 13px;
    color: #65b0c9;
    font-weight: bold;
  }

  .card-tranding {
    /* border-radius: 5% 5% 0 0; */
    border-radius: 20px;
  }

  .totalcari-tranding {
    color: #fdb738;
  }

  .totalcari-rekom {
    color: #fdb738 !important;
  }
}

.banner-gift {
  width: 1120px;
  position: relative;
  left: -20px;
  right: 30px;
  height: 200px;
  /* width: 100%;
  position: relative;
  left: 0px;
  right: 0px; */
}
.kotak-img {
  height: 160px;
  width: 95px;
  justify-content: center;
}

.kotak-img img {
  border-radius: 20px 20px 0 0;
  height: 100%;
  width: 140%;
  object-fit: cover;
}
.card-rekom {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  border: 0.1px;
  width: 215px;
  height: 325px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.kotak-img-rekom {
  /* background:yellow; */
  height: 60%;
  width: 90%;
  align-self: center;
  padding: 10px 0;
}
.kotak-img-rekom img {
  border-radius: 20px 20px 0 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* margin-left:20px; */
}
.kotak-body-rekom {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  height: 40%;
  border-radius: 0 0 20px 20px;
}
.title-card {
  width: 90%;
}

.judul-tranding {
  font-size: 15px;
  color: #65b0c9;
  font-weight: bold;
}

.card-tranding {
  /* border-radius: 5% 5% 0 0; */
  border-radius: 20px;
}

.totalcari-tranding {
  color: #fdb738;
}

.totalcari-rekom {
  color: #fdb738 !important;
}

a:hover {
  text-decoration: none;
}

.flex-kotak-populer {
  /* border:1px solid rgb(221, 213, 213); */
  border-radius: 20px;
}

.kotak-img-populer {
  width: 150px;
  height: 150px;
  background: white;
}

.image-populer {
  width: 150px;
  height: 160px;
  object-fit: cover;
  border-radius: 15% 15% 0 0;
}

.kotak-text-populer {
  width: 150px;
  height: 150px;
  background: white;
}
.judul-populer {
  text-align: left;
  color: #65b0c9;
  font-size: 17px;
  font-weight: bold;
}
.harga-dan-loc-populer {
  position: absolute;
  top: 55%;
}
.harga-dan-loc-populer p:nth-child(1) {
  text-align: right;
  color: #e0a800;
  font-weight: bold;
  font-size: 22px;
}
.harga-dan-loc-populer p:nth-child(2) {
  text-align: left;
  position: relative;
  top: -10px;
}
</style>

<style lang="scss" scoped>
.birujaja-keunggulan {
  background: #a0cadb;
}

.border-warning {
  border: 3px solid #ffc107 !important;
}

.carousel-3d-slide.current {
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
  z-index: 999;
  position: relative;
  background-repeat: no-repeat;
}

.carousel-3d-slide {
  opacity: 0.5 !important;
  border-color: transparent;
}

.carousel-3d-container {
  .carousel-3d-slide {
    margin-left: -65%;
  }
}

// CSS Mobile
.con-mob {
  padding: 10px;
}
.title-mobile {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: 0.28px;
}
.image-populer-m {
  width: 80px;
  height: 67px;
  object-fit: cover;
  border-radius: 15% 15% 0 0;
}
.judul-populer-m {
  text-align: left;
  color: #65b0c9;
  font-size: 9px;
  font-weight: 500;

  letter-spacing: -0.09px;
}
.harga-dan-loc-populer-m {
  position: absolute;
  top: 55%;
}
.harga-dan-loc-populer-m p:nth-child(1) {
  text-align: right;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.08px;
  color: var(--Secondary-30, #fdb739);
  font-size: 8px;
}
.harga-dan-loc-populer-m p:nth-child(2) {
  text-align: left;
  position: relative;
  top: -10px;
}
.kotak-image-m {
  width: 80px;
  height: 66.667px;
  flex-shrink: 0;
  border-radius: 4px 0px 0px 4px;
  background: url(<path-to-image>) lightgray 50% / cover no-repeat;
}
.kotak-popular-m {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  border-radius: 0px 4px 4px 0px;
}
nav-bar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.center-indicators .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -15px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.custom-background {
  background-image: url("../../assets/icon-s/categori-bg.png");
  background-size: cover;
  background-position: center;
}
.custom-background-ship {
  background-image: url("../../assets/icon-s/bg-ship.png");
  background-size: cover;
  background-position: center;
}
.banner {
  display: flex;
  height: 150px;
}

.section {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.left-section {
  background: linear-gradient(255deg, #fdb739 34.12%, #ff7817 91.84%);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
}

.right-section {
  background: linear-gradient(275deg, #61aaff 20.47%, #074d8e 100%);

  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  margin-left: -154px;
}

.content {
  margin-left: 270px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.logo {
  max-height: 80px;
  margin-right: 20px;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.left-section .text-container {
  align-items: flex-start;
}

.right-section .text-container {
  align-items: flex-end;
}

.title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
}

.subtitle {
  font-size: 1em;
  margin: 0;
}
.logo-masdis {
  width: 189px;
  height: 80px;
  flex-shrink: 0;
}
.logo-ebh {
  width: 189px;
  height: 73px;
}
.skeleton-box {
  background-color: #ccc;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #ccc;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-gif {
  width: 300px; /* Atur ukuran gif loading sesuai kebutuhan */
  height: auto;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.header h1 {
  font-size: 24px;
  color: #ff0000;
}
.header-details span {
  margin: 0 10px;
  font-size: 14px;
}
.view-all {
  background-color: #ff0000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.main-banner img {
  width: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  // margin: 20px 0;
}
.products {
  display: flex;
  flex-wrap: wrap;
  // gap: 20px;
}
.products {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px; /* Adjust spacing */
}

.product-container {
  width: 25%; /* 4 items per row */
  padding-left: 15px; /* Adjust spacing */
  box-sizing: border-box;
}

.product {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  height: 280px;
}

.product-image {
  max-width: 180px;
  width: 100%;
  height: 150px; /* Adjust height as needed */
  object-fit: contain;
}

.product-name {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-discount {
  color: var(--Alerts-Red-100, #c53434);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.16px;
  padding-bottom: 10px;
}
</style>
