<template>
  <div>
    <div class="card-register d-none d-lg-block">
      <div class="kotak-register">
        <div>
          <form @submit.prevent="register">
            <div class="d-flex justify-content-between bd-highlight">
              <div class="bd-highlight">
                <h5 class="font-weight-bold" style="margin-left: -60%">
                  <b>Daftar</b>
                </h5>
                <div class="d-flex justify-content-between bd-highlight mb-2">
                  <div class="bd-highlight">
                    <small>Isikan dengan lengkap</small>
                  </div>
                </div>
              </div>
              <div class="bd-highlight"></div>
              <div class="bd-highlight">
                <router-link to="/">
                  <img
                    src="https://jsonx.jaja.id/asset/home/logo/jaja-logo.png"
                    alt=""
                    style="width: 8vw; height: auto"
                  />
                </router-link>
              </div>
            </div>

            <div class="mb-4">
              <div class="row">
                <!-- <div class="col-md-2">
						<div class="text-left mb-1">Title</div>
						<input type="email" v-model="form.email" name="email" class="form-control" required="" >
					</div> -->

                <div class="col-md-12">
                  <div class="text-left mb-1">Nama</div>
                  <input
                    type="text"
                    v-model="namalengkap"
                    name="namalengkap"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-left mb-1">Email</div>
                  <input
                    type="email"
                    v-model="email"
                    name="email"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="mb-4">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-left mb-1">No. HP</div>
                  <input
                    type="phone"
                    v-model="phone"
                    name="phone"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-left mb-1">Password</div>
                  <input
                    :type="setTypeInput"
                    v-model="password"
                    name="password"
                    class="form-control"
                    required
                  />
                  <!-- <div class="d-flex justify-content-between bd-highlight">
							<div class="bd-highlight">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
								<label class="form-check-label" for="flexCheckDefault">
								<small>Lihat Password</small> 
								</label>
							</div>
							</div>
						</div> -->
                </div>
                <!-- <div class="col-md-6">
						<div class="text-left mb-1">Ulangi Password</div>
						<input type="password" v-model="ulangipassword" name="email" class="form-control" required="" >
						<div class="d-flex justify-content-between bd-highlight">
							<div class="bd-highlight">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
								<label class="form-check-label" for="flexCheckDefault">
								<small>Lihat Password</small> 
								</label>
							</div>
							</div>
						</div>
					</div> -->
              </div>
            </div>

            <div class="d-flex justify-content-between bd-highlight mb-4">
              <div class="bd-highlight">
                <div class="form-check">
                  <input
                    @click="seePass"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    <small>Lihat Password</small>
                  </label>
                </div>
              </div>
            </div>

            <button
              @click="submitRegister"
              type="button"
              class="btn bg-primary-jaja text-white font-weight-bold btn-block"
            >
              Daftar
            </button>
          </form>
        </div>
        <div class="">
          <div class="row center-middle-custom">
            <div class="col-md-4">
              <hr style="border-color: grey" />
            </div>
            <div class="col-md-4">
              <div class="d-flex justify-content-center bd-highlight">
                <div class="p-2 bd-highlight h6 text-secondary">
                  atau daftar dengan
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <hr style="border-color: grey" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <GoogleLogin
              :params="params"
              :onSuccess="onSuccess"
              :onFailure="onFailure"
              class="btn btn-block border border-dark"
            >
              <div class="d-flex justify-content-center bd-highlight">
                <div class="bd-highlight mr-2">
                  <img
                    src="https://nimda.jaja.id/asset/icon-jaja/google.png"
                    width="20"
                    alt=""
                  />
                </div>
                <div class="bd-highlight mt-1 text-secondary">Google</div>
              </div>
            </GoogleLogin>
          </div>
        </div>
        <div class="mt-4 kotak-bawah">
          <div class="d-flex justify-content-between bd-highlight mt-4 p-2">
            <div class="p-2 bd-highlight">
              <small>Sudah memiliki akun?</small>
            </div>
            <div class="p-2 bd-highlight">
              <button
                @click="goToLogin"
                class="tombol-masuk p-1 text-dark font-weight-bold"
              >
                Masuk
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none" style="background-color: white">
      <div class="p-mobile-regis">
        <div>
          <img
            style="width: 250px"
            src="https://jsonx.jaja.id/asset/home/logo/jaja-logo.png"
            alt=""
          />
        </div>
        <div>
          <form @submit.prevent="register">
            <div class="input-container">
              <p
                style="
                  color: var(--Neutral-60, #fdb739);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px; /* 142.857% */
                  letter-spacing: 0.14px;
                "
              >
                Daftar
              </p>
              <!-- <span
                style="
                  color: var(--Neutral-60, #1a1a1a);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 142.857% */
                  letter-spacing: 0.14px;
                "
              >
                isi dengan lengkap
              </span> -->
              <label class="mt-3" for="email">Nama Lengkap</label>
              <input
                type="text"
                v-model="namalengkap"
                name="namalengkap"
                class="input-field"
                placeholder="Masukan nama lengkap anda"
                required=""
              />
            </div>
            <div class="input-container">
              <label for="email">Email</label>
              <input
                type="email"
                v-model="email"
                class="input-field"
                placeholder="Masukan amail anda"
                required=""
              />
            </div>
            <div class="input-container">
              <label for="email">No Hp</label>
              <input
                type="phone"
                v-model="phone"
                name="phone"
                class="input-field"
                placeholder="Masukan no hp anda"
                required=""
              />
            </div>
            <div class="input-container">
              <label for="password">Password</label>
              <input
                :type="setTypeInput"
                v-model="password"
                class="input-field"
                placeholder="Masukan password anda"
                required=""
              />
            </div>
            <div class="input-container">
              <div class="mt-3">
                <button
                  style="
                    display: flex;
                    padding: 12px 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    background: var(--Primary-40, #65b0c9);
                    border: #65b0c9;
                    width: 100%;
                  "
            @click="submitRegister"
                >
                  <span
                    style="
                      color: var(--Neutral-10, #fff);
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 18px; /* 150% */
                      letter-spacing: 0.12px;
                    "
                    >Daftar</span
                  >
                </button>

                <GoogleLogin
                  :params="params"
                  :onSuccess="onSuccess"
                  :onFailure="onFailure"
                  class="mt-3"
                  style="
                    display: flex;
                    width: 100%;
                    padding: 12px 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border: 1px solid var(--Secondary-30, #fdb739);
                    background-color: white;
                  "
                >
                  <div class="d-flex justify-content-center bd-highlight">
                    <div
                      style="
                        color: var(--Secondary-30, #fdb739);

                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px; /* 150% */
                        letter-spacing: 0.12px;
                      "
                    >
                      Daftar via Google
                    </div>
                  </div>
                </GoogleLogin>
              </div>
            </div>
          </form>
        </div>
        <p
          class="mt-5"
          style="
            color: var(--Neutral-40, #a4a4a4);

            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 150% */
            letter-spacing: 0.12px;
          "
        >
          Sudah punya akun?
          <span
            @click="goToLogin"
            style="
              color: var(--Secondary-30, #fdb739);
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px; /* 150% */
              letter-spacing: 0.12px;
            "
            >Masuk</span
          >
        </p>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import GoogleLogin from "vue-google-login";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      reqForm: {
        fullName: "",
        email: "",
        password: "",
        phone: "",
      },
      params: {
        client_id:
          "284366139562-tnj3641sdb4ia9om7bcp25vh3qn5vvo8.apps.googleusercontent.com",
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      setTypeInput: "password",
    };
  },
  computed: {
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  components: {
    GoogleLogin,
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://apis.google.com/js/client:platform.js?onload=renderButton"
    );
    document.head.appendChild(externalScript);
    window.scrollTo(0, 0);
  },
  methods: {
    submitRegister() {
      //   this.reqForm.name = this.namadepan + this.namabelakang;
      if (
        this.namalengkap !== "" &&
        this.email !== "" &&
        this.password !== "" &&
        this.phone !== ""
      ) {
        this.reqForm.fullName = this.namalengkap;
        this.reqForm.email = this.email;
        this.reqForm.password = this.password;
        this.reqForm.phone = this.phone;
        this.daftar();
      } else {
        this.$swal.fire("Warning", "Semua data harus diisi", "warning");
      }
    },
    daftar() {
      this.$swal.fire({
        html: '<img src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=t3aj7ofie0camk7k1v2d6s8llbr7q3kb");

      let raw = JSON.stringify(this.reqForm);

      let requestOptions = {
        method: "post",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/register`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            // $("#modalUpdate").modal('hide')
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                this.$router.push({
                  name: "RegisterVerification",
                  // params: { email: this.email },
                });
                location.reload();
              });
          } else if (res.status.code === 409) {
            // $("#modalUpdate").modal('hide')
            this.$swal.fire("Info", res.status.message, "info").then(() => {});
          } else {
            // $("#modalUpdate").modal('hide')
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {});
          }
        })
        .catch((error) => console.log("error", error));
    },
    goToLogin() {
      this.$router.push("/user/login");
      location.reload();
    },
    seePass() {
      event.target.checked
        ? (this.setTypeInput = `text`)
        : (this.setTypeInput = `password`);
      // console.log(this.setTypeInput);
    },
    onSuccess(googleUser) {
      this.successLoginGoogle(googleUser);

      // console.log('googleUser',googleUser);
      // This only gets the user information: id, name, imageUrl and email
      // console.log('googleUser.getBasicProfile()', googleUser.getBasicProfile());
    },
    successLoginGoogle(res) {
      var userProfile = res.getBasicProfile();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: Object.values(userProfile)[5],
        fullName: Object.values(userProfile)[1],
        image: Object.values(userProfile)[4],
        fromLogin: "google",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      // fetch("https://jaja.id/backend/user/google", requestOptions)
      fetch(`${this.domainURL}user/google`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("token", result.data);
          this.getUserProfile(res);
        })
        .catch((error) => console.log("error", error));
    },
    getUserProfile(req) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // fetch("https://jaja.id/backend/user/profile", requestOptions)
      fetch(`${this.domainURL}user/profile`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          // console.log("🚀 ~ file: logingoogle.php ~ line 157 ~ getUserProfile ~ res", res)
          localStorage.setItem("data_profile_user", result);
          // console.log('data_profile_user', result);
          this.getCustomerId(req, res);
        })
        .catch((error) => console.log("error", error));
    },
    getCustomerId(req, res) {
      if (res.status.code == 200) {
        setTimeout(() => {
          this.setUserData(req.email);
        }, 200);
      }
    },
    setUserData(email) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://jsonx.jaja.id/auth/makeSessionIdCustomer/${email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.status.code == 200) {
            let url = new URL(this.dataUrl.url);
            window.location.href = url.origin;
          } else {
            this.$swal.fire("Warning", result.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    onFailure() {
      this.$swal.fire("Warning", "Fail to login using google", "warning");
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: grid;
  margin: 20px;
  align-items: center; /* Pusatkan vertikal */
}

.input-field {
  border: none;
  border-bottom: 1px solid #65b0c9;
  outline: none;
  font-size: 16px;
  padding: 10px 0;
  width: 100%;
}

.input-field:focus {
  border-bottom: 1px solid #65b0c9;
}

label {
  color: var(--Neutral-60, #1a1a1a);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
  text-align: left; /* Posisi label di pojok kanan */
}
.p-mobile-regis {
  padding-top: 140px;
  padding-right: 10px;
  padding-left: 10px;
  width: 430px;
}
.kotak-register {
  width: 30vw;
  height: auto;
  margin-top: 120px;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 8px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 100%;
}
.kotak-bawah {
  position: relative;
  width: 30vw;
  margin-left: -20px;
  // margin-top:10px;
  background-color: #f0f0f0;
  border-radius: 0 0 20px 20px;
}

.opacity-button {
  opacity: 0.5;
}
.tombol-fb {
  width: 50vh;
}

.tombol-masuk {
  background-color: rgb(239 194 122);
  border-radius: 10px;
  font-size: 12px;
  width: 5vw;
  border: 0px !important;
}
</style>