<template>
  <div>
    <div class="d-lg-none">
      <div
        class="shadow-custom"
        style="background-color: #fdb739; height: 80px"
      >
        <div class="container-nav-m d-flex">
          <div class="mr-2">
            <form @submit="pencarianEnter()">
              <div>
                <div
                  style="
                    border: 1px solid var(--Secondary-30, #fdb739);
                    border-radius: 4px;
                    /* width: 290px; */
                  "
                  class="input-group mb-3"
                >
                  <div class="input-group-prepend">
                    <span
                      @click="pencarianEnter()"
                      class="input-group-text"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M7.66667 7.66667L11 11M4.88889 8.77778C2.74112 8.77778 1 7.03667 1 4.88889C1 2.74112 2.74112 1 4.88889 1C7.03667 1 8.77778 2.74112 8.77778 4.88889C8.77778 7.03667 7.03667 8.77778 4.88889 8.77778Z"
                          stroke="#FDB739"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /></svg
                    ></span>
                  </div>
                  <input
                    style="
                      border-radius: 4px;
                      border: none;
                      background: var(--Neutral-10, #fff);
                      display: flex;
                      height: 36px;
                      padding: 8px 12px;
                      align-items: center;
                      gap: 10px;
                      flex: 1 0 0;
                    "
                    type="text"
                    class="form-control"
                    @click="pencarianData"
                    @keypress="pencarianData"
                    @keyup="pencarianData"
                    @keyup.enter="pencarianEnter()"
                    v-model="srcData"
                    autocomplete="off"
                    id="inputPencarian"
                    placeholder="Search here"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div class="triggerMiddleware" v-if="isOpen">
                <!-- {{hiddenCardPencarian}} -->
                <ul
                  class="triggerMiddleware list-group ul-pencarian shadow-custom"
                  style="
                    height: 400px;
                    width: 85%;
                    overflow-y: auto !important;
                    /* max-width: 600px !important; */
                  "
                >
                  <div v-for="(item, index) in dataCombine" :key="index">
                    <label
                      class="triggerMiddleware d-flex container p-2 text-white"
                      style="
                        background-color: #65b0c9 !important;
                        margin-bottom: 0px;
                        border-radius: 2px;
                      "
                      for=""
                      >{{
                        index == 0
                          ? `Produk`
                          : index == 1
                          ? `Toko`
                          : index == 2
                          ? `Kategori`
                          : ``
                      }}</label
                    >
                    <div
                      :class="
                        index
                          ? index == 1
                            ? `triggerMiddleware cardtoko `
                            : index == 2
                            ? `triggerMiddleware cardkategori  `
                            : ``
                          : `triggerMiddleware cardproduk `
                      "
                      style="margin-bottom: 15px"
                    >
                      <div
                        v-if="item.length > 0"
                        class="triggerMiddleware"
                        style="width: 100%; text-align: left"
                      >
                        <div v-for="(a, b) in item" :key="b">
                          <!-- PRODUK -->
                          <template v-if="index == 0">
                            <li
                              @click="setPage(index, a.slug, a.name)"
                              class="triggerMiddleware tobepointer list-group-item d-flex"
                              style="cursor: pointer"
                            >
                              <span class="mr-3"> </span>
                              {{ a.name }}
                            </li>
                          </template>

                          <!-- KATEGORI -->
                          <template v-else-if="index == 1">
                            <li
                              @click="setPage(index, a.slug, a.name)"
                              class="triggerMiddleware tobepointer list-group-item d-flex"
                              style="cursor: pointer"
                            >
                              <span class="mr-3"
                                ><img
                                  :src="a.image"
                                  width="30"
                                  height="30"
                                  alt=""
                                      style="object-fit: contain"
                                />
                              </span>
                              {{ a.name }}
                            </li>
                          </template>
                          <!-- TOKO -->
                          <template v-else-if="index == 2">
                            <li
                              @click="setPage(index, a.slug, a.name)"
                              class="triggerMiddleware tobepointer list-group-item d-flex"
                              style="cursor: pointer"
                            >
                              <span class="mr-3"
                                ><img
                                  :src="a.icon"
                                  width="30"
                                  height="30"
                                      style="object-fit: contain"
                                  alt=""
                                />
                              </span>
                              {{ a.name }}
                            </li>
                          </template>
                        </div>
                      </div>

                      <div v-else>
                        <li class="triggerMiddleware list-group-item d-flex">
                          Data tidak ditemukan
                        </li>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </form>
          </div>
          <div class="mr-2">
            <router-link :to="'/cart/cart'">
              <button
                style="
                  background: #fff;
                  display: flex;
                  width: 36px;
                  height: 36px;
                  padding: 8px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  flex-shrink: 0;
                  border-radius: 4px;
                  border: 1px solid var(--Secondary-30, #fdb739);
                  background: #fff;
                "
              >
                <div
                  style="
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                    fill: var(--Primary-40, #65b0c9);
                    margin-bottom: 10px;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.82411 0.854088C5.07297 0.349157 5.6075 0 6.22677 0H9.7733C10.3925 0 10.9271 0.349157 11.176 0.854088C11.782 0.858329 12.2544 0.885107 12.676 1.0437C13.1797 1.2332 13.6179 1.55533 13.9404 1.97325C14.2657 2.39487 14.4185 2.93527 14.6287 3.67908L15.2865 6.00122L15.5351 6.71911C15.5422 6.72756 15.5492 6.73601 15.5561 6.74463C16.3549 7.72911 15.9745 9.19334 15.2139 12.1218C14.73 13.9844 14.4881 14.9156 13.7666 15.4578C13.0451 16 12.0477 16 10.0529 16H5.94715C3.95235 16 2.95495 16 2.23343 15.4578C1.5119 14.9156 1.26999 13.9844 0.786185 12.1218C0.025499 9.19334 -0.35484 7.72911 0.443865 6.74463C0.450852 6.73601 0.457901 6.72747 0.465011 6.71903L0.713605 6.00121L1.37143 3.67908C1.58164 2.93528 1.73435 2.39487 2.05968 1.97325C2.38217 1.55533 2.82035 1.2332 3.32413 1.04371C3.74575 0.885107 4.21808 0.858329 4.82411 0.854088ZM4.82546 2.13529C4.23862 2.141 4.00313 2.16257 3.80825 2.23588C3.53699 2.33792 3.30105 2.51137 3.1274 2.7364C2.97125 2.93876 2.87963 3.22149 2.62171 4.13187L2.11672 5.91435C3.02116 5.76007 4.25626 5.76007 5.94715 5.76007H10.0529C11.7438 5.76007 12.9789 5.76007 13.8834 5.91435L13.3783 4.13187C13.1204 3.22149 13.0288 2.93876 12.8727 2.7364C12.699 2.51137 12.4631 2.33792 12.1919 2.23588C11.997 2.16257 11.7615 2.141 11.1746 2.13529C10.9252 2.63875 10.3915 2.98665 9.7733 2.98665H6.22677C5.60862 2.98665 5.07489 2.63875 4.82546 2.13529Z"
                      fill="#65B0C9"
                    />
                  </svg>
                </div>
              </button>
            </router-link>
          </div>
          <div class="mr-2">
            <router-link :to="'/pemberitahuan'">
              <button
                style="
                  background: #fff;
                  display: flex;
                  width: 36px;
                  height: 36px;
                  padding: 8px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  flex-shrink: 0;
                  border-radius: 4px;
                  border: 1px solid var(--Secondary-30, #fdb739);
                  background: #fff;
                "
              >
                <div
                  style="
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                    fill: var(--Primary-40, #65b0c9);
                    margin-bottom: 10px;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                  >
                    <path
                      d="M10.3337 17.7649C10.1382 18.9313 9.15686 19.7778 8.00037 19.7778C6.84358 19.7778 5.86195 18.9313 5.6664 17.7649L5.65446 17.6938H10.3457L10.3337 17.7649Z"
                      fill="#65B0C9"
                    />
                    <path
                      d="M0.0173626 14.4298C0.208767 14.2339 0.403793 14.0683 0.592471 13.908C1.38602 13.234 2.07143 12.6519 2.07143 10.2349V8.31781C2.07143 5.49875 4.0078 3.02513 6.68702 2.40493C6.57963 2.18951 6.52519 1.96309 6.52519 1.7306C6.52519 0.898958 7.18681 0.222229 8.00015 0.222229C8.81326 0.222229 9.47478 0.898958 9.47478 1.7306C9.47478 1.96325 9.42041 2.18962 9.31295 2.40493C11.9923 3.02513 13.9285 5.49875 13.9285 8.31781V10.2349C13.9285 12.6519 14.6139 13.234 15.4075 13.908C15.5961 14.0683 15.7912 14.2339 15.9826 14.4298L16 14.4476V16.3496H0V14.4476L0.0173626 14.4298Z"
                      fill="#65B0C9"
                    />
                  </svg>
                </div>
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="showSearchDropdown"
        style="background-color: #65b0c9"
        class="search-dropdown"
      >
        <!-- Form input untuk dropdown pencarian -->
        <div style="width: 100%; padding: 20px">
          <form @submit="pencarianEnter()">
            <div>
              <input
                style="
                  height: 41px;
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px;
                  width: 80%;
                "
                @click="pencarianData()"
                @keypress="pencarianData()"
                @keyup="pencarianData()"
                v-model="srcData"
                id="inputPencarian"
                type="text"
                placeholder="Search..."
              />
              <button
                style="
                  height: 40px;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                  transform: translateY(-1px);
                  width: 20%;
                  background-color: #fdb739;
                "
                @click="pencarianEnter()"
              >
                Search
              </button>
            </div>
            <div class="triggerMiddleware" v-if="isOpen">
              <!-- {{hiddenCardPencarian}} -->
              <ul
                class="triggerMiddleware list-group ul-pencarian shadow-custom"
                style="
                  height: 400px;
                  width: 90%;
                  overflow-y: auto !important;
                  /* max-width: 600px !important; */
                "
              >
                <div v-for="(item, index) in dataCombine" :key="index">
                  <label
                    class="triggerMiddleware d-flex container p-2 text-white"
                    style="
                      background-color: #65b0c9 !important;
                      margin-bottom: 0px;
                      border-radius: 2px;
                    "
                    for=""
                    >{{
                      index == 0
                        ? `Produk`
                        : index == 1
                        ? `Toko`
                        : index == 2
                        ? `Kategori`
                        : ``
                    }}</label
                  >
                  <div
                    :class="
                      index
                        ? index == 1
                          ? `triggerMiddleware cardtoko `
                          : index == 2
                          ? `triggerMiddleware cardkategori  `
                          : ``
                        : `triggerMiddleware cardproduk `
                    "
                    style="margin-bottom: 15px"
                  >
                    <div
                      v-if="item.length > 0"
                      class="triggerMiddleware"
                      style="width: 100%; text-align: left"
                    >
                      <div v-for="(a, b) in item" :key="b">
                        <!-- PRODUK -->
                        <template v-if="index == 0">
                          <li
                            @click="setPage(index, a.slug, a.name)"
                            class="triggerMiddleware tobepointer list-group-item d-flex"
                            style="cursor: pointer"
                          >
                            <span class="mr-3"> </span>
                            {{ a.name }}
                          </li>
                        </template>

                        <!-- KATEGORI -->
                        <template v-else-if="index == 1">
                          <li
                            @click="setPage(index, a.slug, a.name)"
                            class="triggerMiddleware tobepointer list-group-item d-flex"
                            style="cursor: pointer"
                          >
                            <span class="mr-3"
                              ><img
                                :src="a.image"
                                width="30"
                                height="30"
                                alt=""
                              />
                            </span>
                            {{ a.name }}
                          </li>
                        </template>
                        <!-- TOKO -->
                        <template v-else-if="index == 2">
                          <li
                            @click="setPage(index, a.slug, a.name)"
                            class="triggerMiddleware tobepointer list-group-item d-flex"
                            style="cursor: pointer"
                          >
                            <span class="mr-3"
                              ><img
                                :src="a.icon"
                                width="30"
                                height="30"
                                alt=""
                              />
                            </span>
                            {{ a.name }}
                          </li>
                        </template>
                      </div>
                    </div>

                    <div v-else>
                      <li class="triggerMiddleware list-group-item d-flex">
                        Data tidak ditemukan
                      </li>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showSearchDropdown: false,
      showMenu: false,
      chatNotifications: 0,
      notificationNotifications: 0,
      cartNotifications: 0,
      isScrolled: false,
      // isLoggedIn: false,
      // showOrHiddenLogout: false,
      srcData: "",
      typingTimer: "",
      doneTypingInterval: 700,
      dataCombine: [],
      hiddenCardPencarian: false,
      dataToko: {},
      dataCategory: {},
      dataProduct: {},
      dataDitemukan: {},
      masterKategori: {},
      masterInfo: {},
      cekMouseKategori: false,
      cekMouseJaja: false,
      cekMouseEBH: false,
      cekMouseMasdis: false,
      showOrHiddenLogout: false,
      childCategori: {
        length: 0,
        data: "",
      },
      indexParentActive: 0,

      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ["dblclick", "click"],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
      },
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    // ...mapGetters({ totalKeranjang: "infoGetters" }),
    ...mapGetters({ user: "user" }),
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  created() {
    this.srcData = this.srcData === "" ? this.$route.params.id : "";
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    this.getMasteKategori();
    // this.escapeFunction()
  },
  mounted() {
    this.getNotif();
    this.getInfo();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleSearchDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleScroll() {
      // Mengubah nilai isScrolled berdasarkan perilaku scroll
      if (window.scrollY > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },

    onClickOutside(event) {},
    handler(event) {
      this.isOpen = false;
    },
    middleware(event) {
      return event.target.className.split(" ")[0] !== "triggerMiddleware";
    },
    escapeFunction() {
      // this.hiddenCardPencarian=true
      // alert('dsadsa')
      // let that = this;
      // document.addEventListener('keyup', function (evt) {
      //     if (evt.keyCode === 27) {
      //       console.log(this.hiddenCardPencarian)
      //       this.hiddenCardPencarian=true
      //     }
      // });
    },
    onEnter(e) {
      this.$router.push("/product-search/" + e);
    },
    pencarianEnter() {
      this.$router.push("/product-search/" + this.srcData);
      // document.getElementsByClassName('ul-pencarian')[0].style.display = 'none'
    },
    pencarianData(event) {
      this.srcData = event.target.value;
      clearTimeout(this.typingTimer);
      if (this.srcData.trim() === "") {
        this.isOpen = false;
      } else {
        this.isOpen = true;
        this.typingTimer = setTimeout(() => this.doneTyping(this.srcData), 500);
      }
    },
    doneTyping(string) {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=vah7ivbaoqeus4qfh89d7c8o2q55216c");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/search?limit=5&keyword=${string}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.dataCombine = [];
          this.hiddenCardPencarian = false;
          const res = JSON.parse(result);
          this.dataToko = res.data.store;
          this.dataCategory = res.data.category;
          this.dataProduct = res.data.product;
          this.dataDitemukan = true;
          this.dataCombine.push(res.data.product);
          this.dataCombine.push(res.data.store);
          this.dataCombine.push(res.data.category);

          console.log("dataCategory", res.data.category);
          console.log("dataProduct", res.data.product);
        });
    },
    getInfo() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/info`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.cartNotifications = res.data.totalProductInCart || 0;
            this.notificationNotifications = res.data.totalNotifUnread || 0;
            // Set data lain jika perlu
          }
        })
        .catch((error) => console.log("error", error));
    },
    getNotif() {
      const database = firebase.database();
      const uid = this.user.uid;

      database.ref(`/friend/${uid}`).on("value", (snapshot) => {
        let countChat = 0;
        snapshot.forEach((snap) => {
          const item = snap.val();
          if (item && item.id !== uid && item.id !== "null" && item.amount) {
            countChat += item.amount;
          }
        });
        this.chatNotifications = countChat;
      });
    },
    getMasteKategori() {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=mgqcv1a854uuigbeae07b3hue1hcdgo8");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/category`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.masterKategori = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    mouseMasuk() {
      this.cekMouseKategori = true;
    },
    mouseKeluar() {
      this.cekMouseKategori = false;
    },
    mouseMasukJaja() {
      this.cekMouseJaja = true;
    },
    mouseKeluarJaja() {
      this.cekMouseJaja = false;
    },
    mouseMasukEBH() {
      this.cekMouseEBH = true;
    },
    mouseKeluarEBH() {
      this.cekMouseEBH = false;
    },
    mouseMasukMasdis() {
      this.cekMouseMasdis = true;
    },
    mouseKeluarMasdis() {
      this.cekMouseMasdis = false;
    },
    mouselistover(index) {
      this.indexParentActive = index;
      this.childCategori.length = this.masterKategori[index].children.length;
      this.childCategori.data = this.masterKategori[index].children;
    },
    gotoDetailProdukNavbar(slug) {
      this.$router.push("/kategori/" + slug);
      this.cekMouseKategori = false;
    },
    mouselistchildover(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.add("active");
      this.cekMouseKategori = true;
    },
    mouselistchildleave(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.remove("active");
    },
    setPage(index, slug, name) {
      if (index == 0) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailProduk/${slug}`);
      } else if (index == 1) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailToko/${slug}`);
      } else {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/kategori/${slug}`);
      }
      this.dataCombine = [];
    },
    logout() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda akan keluar?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Keluar",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runLogout();
          }
          // else if (result.isDenied) {
          //   this.$swal.fire("Cancel", "Anda tidak Logout", "warning");
          // }
        });
      // localStorage.clear();
      // window.location.href = "${this.domainURL}"
    },
    runLogout() {
      // this.$swal.fire('sukses', 'anda berhasil logout', 'success')
      localStorage.clear();
      setTimeout(() => this.runRedirect(), 500);
    },
    runRedirect() {
      let url = new URL(this.dataUrl.url);
      window.location.href = url.origin;
    },
    showDropDownLogout() {
      this.showOrHiddenLogout
        ? (this.showOrHiddenLogout = false)
        : (this.showOrHiddenLogout = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_pos {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  // background: white;
}
.box-penambal {
  width: 100vh;
  /* height:23vh; */
  background: yellow;
  /* margin-bottom:1%; */
}
/* .ul-pencarian {
      position: absolute;
      z-index: 999;
      background: white;
      width: 55%;
  } */
.ul-pencarian {
  // margin-left:3.4%;
  margin-left: 0;
  position: absolute;
  z-index: 999;
  background: white;
  width: 38%;
  box-shadow: 5px 10px #888888;
  padding: 30px;
}
.card-body {
  background-color: white;
}
.tobepointer:hover {
  cursor: pointer;
}
.cardtoko {
  max-height: 400px;
  overflow: hidden;
}
.cardkategori {
  max-height: 400px;
  overflow: hidden;
}
.cardproduk {
  max-height: 400px;
  overflow: hidden;
}
.warnabirujaja {
  background: #65b0c9 !important;
}
.warnatextbirujaja {
  color: #65b0c9 !important;
}

.warnaorangejaja {
  background: #fdb738 !important;
}
.logo img {
  height: 100px;
}
.searchPencarian {
  // margin-left:8%;
  margin-left: 0;
  width: 600px;
}
/* PHONE */
@media (max-width: 600px) {
  .logo img {
    height: 65px;
  }
  .btn-border {
    border-radius: 0rem 0.1rem 0.1rem 0rem !important;
    border: 0.5px solid #ffc107;
    /* border-top: 1px solid #495057; 
      border-bottom: 1px solid #495057; 
      border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 200px;
  }
  .tab-kategori {
    width: 100px;
    padding: 1px;
    border-radius: 5px 5px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
/* PHONE AND TABLET */
@media (min-width: 600px) {
  .logo img {
    height: 65px;
  }
  .btn-border {
    border-radius: 0rem 0.1rem 0.1rem 0rem !important;
    border: 0.5px solid #ffc107;
    /* border-top: 1px solid #495057; 
      border-bottom: 1px solid #495057; 
      border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 250px;
  }
  .tab-kategori {
    width: 100px;
    padding: 1px;
    border-radius: 5px 5px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

/* TABLET */
@media (min-width: 768px) {
  .logo img {
    height: 100px;
    .btn-border {
      border-radius: 0rem 0.1rem 0.1rem 0rem !important;
      border: 0.5px solid #ffc107;
      /* border-top: 1px solid #495057; 
        border-bottom: 1px solid #495057; 
        border-right: 1px solid #495057; */
    }
    .searchPencarian {
      // margin-left:8%;
      margin-left: 0;
      width: 300px;
    }
    .tab-kategori {
      width: 150px;
      padding: 1px;
      border-radius: 10px 10px 0 0;
    }
    .malltab-list {
      position: relative;
      height: 40px;
      float: left;
      margin-top: 6px;
      box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
      font-size: 14px;
    }
    .malltab-list,
    .malltab-list a {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }
}
/* LAPTOP */
@media (min-width: 992px) {
  .logo img {
    height: 100px;
  }
  .btn-border {
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
    border: 1px solid #ffc107;
    /* border-top: 1px solid #495057; 
      border-bottom: 1px solid #495057; 
      border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 400px;
  }
  .tab-kategori {
    width: 150px;
    padding: 1px;
    border-radius: 10px 10px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
/* DEKSTOP */
@media (min-width: 992px) {
  .logo img {
    height: 100px;
  }
  .btn-border {
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
    border: 1px solid #ffc107;
    /* border-top: 1px solid #495057; 
        border-bottom: 1px solid #495057; 
        border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 600px;
  }
  .tab-kategori {
    width: 150px;
    padding: 1px;
    border-radius: 10px 10px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.searchPencarian input {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
}
.tab-kategori {
  width: 150px;
  padding: 1px;
  border-radius: 10px 10px 0 0;
}

/* CUSTOM BY MALA */
.btn-border {
  border-radius: 0rem 0.25rem 0.25rem 0rem !important;
  border: 1px solid #ffc107;
  /* border-top: 1px solid #495057; 
    border-bottom: 1px solid #495057; 
    border-right: 1px solid #495057; */
}

/* width */
::-webkit-scrollbar {
  // height: 1px;
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.list-group-item.active {
  z-index: 2;
  color: #fff !important;
  background-color: #fcb536 !important;
  border-color: #fcb536 !important;
}

.avatar-with-name {
  color: #65b0c9 !important;
}

.dropdown-item:hover {
  background-color: #65b0c9 !important;
  color: white;
}

.utils {
  position: relative;
  padding: 0;
  background: #ffffff;
}
.utils_innr {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.malltab {
  height: 46px;
  position: relative;
  color: #fff;
  /* overflow: hidden; */
}
.malltab-list.ilotte {
  z-index: 1;
  background-color: white;
}
.malltab-list.hover,
.malltab-list:hover {
  z-index: 10;
  margin-top: 0;
  height: 46px;
}
.malltab-list {
  position: relative;
  height: 40px;
  float: left;
  margin-top: 6px;
  box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
  font-size: 14px;
}
.malltab-list,
.malltab-list a {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* transition: all .2s; */
}

.bg-kategori-kuning {
  background-color: #fcb536 !important;
}

.fa-bell:hover {
  color: #378098;
}

.fa-envelope:hover {
  color: #378098;
}
.fa-shopping-bag:hover {
  color: #378098;
}
.font-bold-baru {
  font-weight: 400;
}
.container-nav-m {
  padding: 20px;
  justify-content: center;
}
.menu-list {
  list-style-type: none; /* Menghilangkan bullet points */
  padding: 0;
}

.menu-list li {
  margin-bottom: 5px; /* Jarak antar menu */
  border-bottom: 1px solid #ccc; /* Garis pembatas */
}

.menu-list li a {
  color: white; /* Warna teks putih */
  text-decoration: none; /* Menghapus garis bawah dari tautan */
}
.pd-list {
  padding: 8px;
}
.input-group-text {
  background-color: white;
  border: none;
}
</style>
